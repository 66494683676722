import * as React from 'react';

function SvgBgMoon2(props) {
  return (
    <svg viewBox="0 0 213 256" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M194.116 94.3345C185.936 97.2944 176.945 96.7949 169.139 92.9521C153.089 85.1843 146.283 65.6347 153.946 49.2903C155.895 45.1159 158.705 41.4198 162.157 38.4158C123.463 2.63673 67.7462 -6.77622 19.6661 14.317C13.9859 30.5068 10.4951 47.392 9.26076 64.5261C28.4379 63.4714 79.7946 63.8333 112.457 95.0497C153.002 133.546 148.857 202.438 148.649 205.381L148.638 205.605C148.525 221.536 146.575 237.39 142.787 252.851C196.132 219.43 219.153 152.512 197.929 92.6381C196.688 93.2801 195.422 93.8458 194.116 94.3345Z"
        fill="#002A3A"
      />
      <path
        d="M190.553 84.0643C201.838 80.002 207.764 67.4078 203.791 55.9326C199.819 44.4573 187.452 38.4499 176.167 42.5122C174.652 43.0504 173.187 43.7559 171.818 44.6163C163.203 50.058 159.46 60.8584 162.814 70.5932C166.875 82.0579 179.228 88.0646 190.553 84.0643Z"
        fill="#002A3A"
      />
    </svg>
  );
}

export default SvgBgMoon2;
