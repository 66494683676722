import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import Vimeo from '@vimeo/player';
import { AnimatePresence, motion } from 'framer-motion';
import { LocalContext } from 'context';
import { Play } from 'assets/svgs';
import { breakpoints, H3, P } from 'styles';
import Thumbnail from '../../../assets/images/about-video-thumbnail.png';

const About = ({ invert }) => {
  const { theme } = useContext(LocalContext);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [vimeoPlayer, setVimeoPlayer] = useState(null);

  useEffect(() => {
    const iframe = document.getElementById('about-video');
    const _vimeoPlayer = new Vimeo(iframe);
    setVimeoPlayer(_vimeoPlayer);
  }, []);

  useEffect(() => {
    if (videoPlaying && vimeoPlayer) {
      vimeoPlayer.play();
    }
  }, [videoPlaying]);

  return (
    <Container id="about" theme={theme} invert={invert}>
      <InnerContainer invert={invert}>
        <H3>About Skillnet Innovation Exchange</H3>
        <Content>
          <P>
            <strong>
              Skillnet Innovation Exchange is where businesses facing digital transformation
              challenges are connected with innovative solutions providers with the talent to help
              them achieve their ambitions.
            </strong>
            <br />
            <br />
            Large corporates get access to Ireland&apos;s most talented solutions providers and to
            collaborations that accelerate digital transformation. Scaling technology companies get
            an instant sales pipeline as well as access to key decision makers, market intelligence
            and the capability to sell to large corporates.
            <br />
            <br />
            Skillnet Innovation Exchange is a multi-year programme that facilitates collaboration
            between Irish SMEs and large multinationals to boost innovation. It will support over
            1,000 companies between 2022-2025.
            <br />
            <br />
            For more see{' '}
            <a href="https://theinnovationexchange.ie/" target="_blank" rel="noreferrer">
              www.theinnovationexchange.ie
            </a>
          </P>
          <VideoWrapper theme={theme} videoPlaying={videoPlaying}>
            <iframe
              title="About Skillnet Innovation Exchange"
              src="https://player.vimeo.com/video/810844478?h=e2d1e4d207&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              id="about-video"
              allow="autoplay;"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%'
              }}
            />
            <AnimatePresence>
              {!videoPlaying && (
                <>
                  <img src={Thumbnail} alt="Video Thumbnail" />
                  <Overlay type="button" onClick={() => setVideoPlaying(true)}>
                    <Play />
                  </Overlay>
                </>
              )}
            </AnimatePresence>
          </VideoWrapper>
        </Content>
      </InnerContainer>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${({ invert }) => (invert ? '#FFFFFF' : ' #ededed')};
  display: grid;
  grid-column: 1/7;
  grid-template-columns: repeat(6, 1fr);
  padding: 22rem 1rem;
  ${breakpoints.sm} {
    grid-column: 2/6;
    /* padding: 6rem 1rem; */
  }
  ${breakpoints.lg} {
    grid-gap: 1.25rem;
    grid-column: 1/13;
    grid-template-columns: repeat(12, 1fr);
    padding: 6rem 1rem;
  }
  ${breakpoints.xl} {
    grid-column: 3/11;
  }
  ${H3} {
    color: ${({ theme }) => theme.tertiary};
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: center;
  }
  ${P} {
    color: ${({ theme }) => theme.primary};
    font-weight: 500;
    a {
      color: ${({ theme }) => theme.primary};
      text-decoration: underline;
      text-underline-position: under;
    }
  }
`;

const InnerContainer = styled.div`
  align-items: center;
  background-color: ${({ invert }) => (invert ? '#FFFFFF' : ' #ededed')};
  display: flex;
  flex-direction: column;
  gap: 2rem;
  grid-column: 1/7;
  grid-column-gap: 1rem;
  justify-content: center;
  width: 100%;
  @media only screen and (min-width: 1150px) {
    grid-column: 2/12;
  }
`;

const Content = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 1.25rem;
  ${P} {
    font-family: 'Uni Neue', sans-serif;
  }
  @media only screen and (min-width: 1150px) {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: 35% 65%;
  }
`;

const VideoWrapper = styled.div`
  border: ${({ theme }) => `3px solid ${theme.tertiary}`};
  height: 0;
  padding-bottom: 56%; /* 16:9 */
  position: relative;
  iframe {
    border-width: 0px;
    z-index: 0;
  }
  img {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
`;

const Overlay = styled(motion.button)`
  background: transparent;
  cursor: pointer;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
  svg {
    height: 100px;
    width: 100px;
  }
`;

export default About;
