import React, { useState, useContext, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { hexToRGB } from 'utils';
import { LoadingSpinner } from 'components';
import { FirebaseContext, LocalContext } from 'context';
import { fadeInAndOutVariants } from 'styles';
import { Star } from 'assets/animated-svgs';
import Emojis from './Emojis';

const FeedbackAnswerModal = ({ poll }) => {
  const { selectedEvent } = useContext(LocalContext);
  const { firebase, user } = useContext(FirebaseContext);
  const [errorMessage, setErrorMessage] = useState('');
  const [userHasAlreadyAnsweredThisPoll, setUserHasAlreadyAnsweredThisPoll] = useState(false);
  const [
    usersSubmittedAnswersFromAlreadyAnsweredPoll,
    setUsersSubmittedAnswersFromAlreadyAnsweredPoll
  ] = useState(null);

  useEffect(() => {
    if (firebase && user) {
      firebase.interaction.polls
        .checkIfUserHasAlreadyAnsweredThisPoll({
          uid: user.uid,
          eid: selectedEvent?.eid,
          pid: poll.pid
        })
        .then((doc) => {
          if (doc.data()) {
            setUserHasAlreadyAnsweredThisPoll(false);
            setUsersSubmittedAnswersFromAlreadyAnsweredPoll(doc.data());
          }
        });
    }
  }, [firebase, user, selectedEvent]);

  console.log(selectedEvent);

  const [contactAgreement, setContactAgreement] = useState(
    usersSubmittedAnswersFromAlreadyAnsweredPoll?.contactAgreement || true
  );
  const [personalMessage, setPersonalMessage] = useState(
    usersSubmittedAnswersFromAlreadyAnsweredPoll?.message || ''
  );
  const [rating, setRating] = useState({ position: -1, id: '' });
  const [hoveredRating, setHoveredRating] = useState({ position: -1, id: '' });
  const [pollStatus, setPollStatus] = useState('');

  const submitButtonIsDisabled = userHasAlreadyAnsweredThisPoll || pollStatus === 'submitted';

  const handleRating = (e, option, index) => {
    e.persist();
    setRating({
      position: index,
      id: option.rid
    });
  };

  const handleChange = (e) => {
    e.persist();
    setPersonalMessage(e.target.value);
  };

  const handleSubmit = async (e) => {
    if (rating.position === -1) {
      setErrorMessage('missing-rating');
    }
    e.preventDefault();
    if (rating.position !== -1 && pollStatus !== 'submitted') {
      try {
        setPollStatus('submitting');

        await firebase.interaction.polls.submitFeedbackAnswer({
          eid: selectedEvent.eid,
          poll,
          uid: user.uid,
          rating,
          personalMessage,
          contactAgreement
        });
        setPollStatus('submitted');
      } catch (error) {
        console.error(error);
        setPollStatus('error');
      }
    }
  };

  return (
    <Underlay colors={selectedEvent.colors} data-underlay>
      <Modal colors={selectedEvent.colors}>
        <Question colors={selectedEvent.colors}>
          <p>{poll.question.text}</p>
        </Question>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Option colors={selectedEvent.colors}>
            <p>Choose a rating</p>
            {poll.ratingOption.ratingType === 'stars' && (
              <Icons pollRatingType={poll.ratingOption.ratingType} errorMessage={errorMessage}>
                {poll.ratingOption.ratingOptions.map((option, index) => (
                  <AnimatedStar
                    key={option.rid}
                    height="24px"
                    width="24px"
                    stroke={
                      index <= rating.position || index <= hoveredRating.position
                        ? selectedEvent?.colors.secondary
                        : errorMessage === 'missing-rating'
                        ? '#F55151'
                        : '#C4C4C4'
                    }
                    fill={index <= rating.position ? selectedEvent?.colors.secondary : 'none'}
                    onClick={(e) => handleRating(e, option, index)}
                    onMouseOver={() => {
                      setHoveredRating({ position: index, id: option.rid });
                      setErrorMessage('');
                    }}
                    onMouseOut={() =>
                      index > rating.position && setHoveredRating({ position: -1, id: '' })
                    }
                  />
                ))}
              </Icons>
            )}
            {poll.ratingOption.ratingType === 'numbers' && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  width: 'fit-content'
                }}>
                <Icons pollRatingType={poll.ratingOption.ratingType} errorMessage={errorMessage}>
                  {poll.ratingOption.ratingOptions.map((option, index) => (
                    <AnimatedNumber
                      key={option.rid}
                      height="24px"
                      width="24px"
                      colors={
                        errorMessage === 'missing-rating'
                          ? '#F55151'
                          : selectedEvent.colors.secondary
                      }
                      fill={
                        index === rating.position || index === hoveredRating.position
                          ? selectedEvent?.colors.secondary
                          : 'transparent'
                      }
                      fontColor={
                        index === rating.position || index === hoveredRating.position
                          ? 'white'
                          : errorMessage === 'missing-rating'
                          ? '#F55151'
                          : 'black'
                      }
                      onClick={(e) => handleRating(e, option, index)}
                      onMouseOver={() => {
                        setHoveredRating({ position: index, id: option.rid });
                        setErrorMessage('');
                      }}
                      onMouseOut={() => setHoveredRating({ position: -1, id: '' })}>
                      <p>{index + 1}</p>
                    </AnimatedNumber>
                  ))}
                </Icons>
                <Credit>
                  <p>Bad</p>
                  <p>Excellent</p>
                </Credit>
              </div>
            )}
            {poll.ratingOption.ratingType === 'emojis' && (
              <Icons pollRatingType={poll.ratingOption.ratingType} errorMessage={errorMessage}>
                <Emojis
                  poll={poll}
                  rating={rating}
                  handleRating={handleRating}
                  hoveredRating={hoveredRating}
                  setHoveredRating={setHoveredRating}
                  setErrorMessage={setErrorMessage}
                  errorMessage={errorMessage}
                  selectedEvent={selectedEvent}
                />
              </Icons>
            )}
          </Option>
          {poll.personalFeedbackMessage && (
            <Option colors={selectedEvent.colors}>
              <p>Leave your personal feedback</p>
              <textarea
                rows="4"
                maxLength="146"
                disabled={userHasAlreadyAnsweredThisPoll}
                onChange={(e) => handleChange(e)}
              />
            </Option>
          )}
          {poll.contactField && (
            <Option colors={selectedEvent.colors}>
              <p>May we contact you regarding your feedback?</p>
              <Checkboxes>
                <OptionCheckbox
                  colors={selectedEvent.colors}
                  isEnabled={contactAgreement}
                  onClick={() => setContactAgreement(true)}>
                  <div>
                    <div />
                  </div>
                  <p>Yes</p>
                </OptionCheckbox>
                <OptionCheckbox
                  colors={selectedEvent.colors}
                  isEnabled={!contactAgreement}
                  onClick={() => setContactAgreement(false)}>
                  <div>
                    <div />
                  </div>
                  <p>No</p>
                </OptionCheckbox>
              </Checkboxes>
            </Option>
          )}
          <SubmitButton
            colors={selectedEvent.colors}
            custom={submitButtonIsDisabled}
            disabled={submitButtonIsDisabled}
            pollStatus={pollStatus}
            submitButtonIsDisabled={submitButtonIsDisabled}
            style={{ margin: '0' }}
            type="submit">
            {pollStatus === 'submitting' ? (
              <LoadingSpinner style={{ width: '1.625rem', color: '#fff' }} />
            ) : pollStatus === 'submitted' ? (
              'Submitted'
            ) : (
              'Submit'
            )}
          </SubmitButton>
        </form>
      </Modal>
    </Underlay>
  );
};

const Underlay = styled(motion.div).attrs(fadeInAndOutVariants())`
  backdrop-filter: blur(2px);
  background: rgba(0, 0, 0, 0.6);
  color: #000;
  height: 100%;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 1.625rem 1.625rem;
  position: absolute;
  top: 0;
  width: 100%;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.31rem grey;
    border-radius: 0.625rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ colors }) => colors.tertiary};
    border-radius: 0.625rem;
  }
`;

const Modal = styled.div`
  background-color: #fff;
  margin: 2rem auto 0;
  max-width: 23.75rem;
  position: relative;
  form {
    display: flex;
    flex-direction: column;
    padding: 1.25rem 1.875rem;
  }
`;

const Question = styled.div`
  background-color: ${({ colors }) => hexToRGB({ color: colors.secondary, alpha: 0.2 })};
  color: ${({ colors }) => colors.secondary};
  padding: 0.75rem 1.5rem;
  width: 100%;
  p {
    font-weight: 700;
  }
`;

const Icons = styled.div`
  border: ${({ errorMessage }) => (errorMessage === 'missing-rating' ? '1px solid red' : 'none')};
  display: flex;
  justify-content: space-between;
  width: ${({ pollRatingType }) =>
    pollRatingType === 'stars' ? '9.5rem' : pollRatingType === 'emojis' ? '12rem' : '100%'};

  > * {
    cursor: pointer;
  }

  > svg {
    box-sizing: content-box;
    flex-shrink: 0;
    padding: 0.25rem;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
`;

const AnimatedStar = styled(Star)`
  flex-grow: 1;
  transition: stroke 3s ease-in-out;
`;

const AnimatedNumber = styled.div`
  align-items: center;
  background: ${({ fill }) => fill};
  border: 1px solid ${({ colors }) => colors};
  border-radius: 2px;
  display: flex;
  font-size: 0.425rem;
  height: 1.5rem;
  justify-content: center;
  margin: 0 0.25rem;
  padding: 0;
  width: 1.5rem;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  p {
    color: ${({ fontColor }) => fontColor} !important;
  }

  &:hover {
    background: ${({ colors }) => colors.secondary};
    p {
      color: #fff;
    }
  }
`;

const Credit = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  > p {
    color: #bdbdbd !important;
    padding: 0 !important;
  }
  > p:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

const Checkboxes = styled.div`
  align-items: center;
  display: flex;
  padding: 0 !important;
  > * {
    &:first-child {
      margin-right: 1.5rem;
    }
  }
`;

const Option = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  p {
    color: #000;
    font-size: 0.875rem;
    font-weight: 300;
    padding: 0.5rem 0;
  }
  textarea {
    border: 1px solid ${({ colors }) => colors.secondary};
    border-radius: 2px;
    color: #4f4f4f;
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 0.825rem;
    font-weight: 300;
    outline: none;
    padding: 0.825rem !important;
    resize: none;
    width: 100%;
  }
  > * {
    &:last-child {
      padding: 0;
    }
  }
`;

const OptionCheckbox = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  > div {
    align-items: center;
    display: flex;
    height: 1rem;
    justify-content: center;
    margin-right: 0.875rem;
    transition: border 200ms ease-in-out;
    width: 1rem;
    > div {
      height: 8px;
      transition: background-color 200ms ease-in-out;
      width: 8px;
    }

    &,
    > div {
      border-radius: 2px;
    }

    ${({ isEnabled }) =>
      isEnabled
        ? css`
            border: 1px solid ${({ colors }) => colors.secondary};
            > div {
              background-color: ${({ colors }) => colors.secondary};
            }
          `
        : css`
            border: 1px solid #c4c4c4;
            > div {
              background-color: transparent;
            }
          `}
  }
`;

const SubmitButton = styled(motion.button).attrs({
  whileTap: (submitButtonIsDisabled) =>
    !submitButtonIsDisabled && {
      scale: 0.95
    }
})`
  align-items: center;
  align-self: flex-end;
  background-color: ${({ submitButtonIsDisabled, pollStatus, colors }) =>
    submitButtonIsDisabled && pollStatus === 'submitted'
      ? colors.tertiary
      : submitButtonIsDisabled && pollStatus !== 'submitted'
      ? 'rgba(196, 196, 196, 0.3)'
      : colors.secondary};
  border-radius: 0.125em;
  color: ${({ submitButtonIsDisabled, pollStatus }) =>
    submitButtonIsDisabled && pollStatus !== 'submitted' ? '#c4c4c4' : '#fff'};
  cursor: ${({ submitButtonIsDisabled, pollStatus }) =>
    pollStatus === 'submitting'
      ? 'none'
      : submitButtonIsDisabled && pollStatus === 'submitted'
      ? 'default'
      : submitButtonIsDisabled && pollStatus !== 'submitted'
      ? 'not-allowed'
      : 'pointer'};
  display: flex;
  font-weight: 700;
  justify-content: center;
  text-transform: uppercase;
  font-size: 1rem;
  height: 2.5em;
  margin-top: 1.25em;
  width: 11.875em;
`;

export default FeedbackAnswerModal;
