import React, { useContext, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Participant } from 'components';
import { FirebaseContext } from 'context';
import { motion } from 'framer-motion';

const Participants = ({ colors, eid, handleParticipantAvatarOrNameClick }) => {
  const { firebase } = useContext(FirebaseContext);
  const particpantsContainerRef = useRef();
  const [lastFetchedParticipantDoc, setLastFetchedParticipantDoc] = useState();
  const [participants, setParticpants] = useState([]);

  const populateEventParticipantsList = (result) => {
    if (!result.empty) {
      setParticpants((currrentValue) => [
        ...currrentValue,
        ...result.docs.map((doc) => {
          const { name, profession, company, socials, avatarUrl, eventRole, uid } = doc.data();
          return {
            name,
            profession,
            company,
            socials,
            avatarUrl,
            eventRole,
            uid
          };
        })
      ]);
      setLastFetchedParticipantDoc(result.docs[result.docs.length - 1]);
    }
  };

  useEffect(() => {
    firebase.interaction.participants
      .fetchAllEventParticipants({ eid, limitFetch: true })
      .then(populateEventParticipantsList);
    return () => {
      setParticpants([]);
      setLastFetchedParticipantDoc(null);
    };
  }, []);

  const fetchPaginatedParticipants = () =>
    firebase.interaction.participants
      .fetchAllEventParticipants({ eid, lastFetchedParticipantDoc, limitFetch: true })
      .then(populateEventParticipantsList);

  const duplicateUsers = participants
    .map(({ uid }) => uid)
    .filter((item, index) => index !== participants.map(({ uid }) => uid).indexOf(item));

  if (duplicateUsers.length) {
    console.warn('Duplicate users: ', duplicateUsers);
  }

  return (
    <Wrapper ref={particpantsContainerRef} colors={colors} tabIndex={0}>
      {participants.length > 0 &&
        participants.map((participant, i, arr) => (
          <Participant
            key={participant.uid}
            eid={eid}
            participant={participant}
            fetchPaginatedParticipants={fetchPaginatedParticipants}
            handleParticipantAvatarOrNameClick={handleParticipantAvatarOrNameClick}
            isLastFetchedParticipant={arr.length === i + 1}
            colors={colors}
            particpantsContainerRef={particpantsContainerRef}
          />
        ))}
    </Wrapper>
  );
};

const Wrapper = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  background-color: #fff;
  padding: 1.25rem;
  min-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.31rem grey;
    border-radius: 0.625rem;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ colors }) => colors.tertiary};
    border-radius: 0.625rem;
  }
`;

export default Participants;
