import React, { useContext } from 'react';
import { navigate } from 'gatsby';
import { Button } from 'components';
import { LocalContext } from 'context';
import styled, { css } from 'styled-components';
import { clearQueryParams } from 'utils';
import { Markup } from 'interweave';
import { LinkedinShareButton, TwitterShareButton, LinkedinIcon, TwitterIcon } from 'react-share';
import { FormContainer } from '../FormComponents';

const NewUserEmailVerification = ({
  setShowNewUserEmailVerificationModal,
  newUserEmailVerified,
  user,
  event
}) => {
  const { theme } = useContext(LocalContext);

  const shareUrl = 'https://www.theinnovationexchangelive.ie';

  const handleButtonClick = () => {
    if (user) {
      if (event && user?.eventsUserCanAccess.includes(event.eid)) {
        navigate(`/events/${event.slug}`);
      }
      clearQueryParams();
      setShowNewUserEmailVerificationModal(false);
    }
  };

  return (
    <FormContainer $theme={theme} color="#fff">
      <Status newUserEmailVerified={newUserEmailVerified}>
        {newUserEmailVerified && user && user.name ? (
          <Markup
            content={`Thanks, ${
              user.name.split(' ')[0]
            }.<br />Your account has been<br />successfully verified.<br /><br />
            The stream starts<br /> Thursday, January 25th at 11am<br /><br />See you then!<br />`}
          />
        ) : (
          'Verifying Email'
        )}
      </Status>
      {newUserEmailVerified && user && user.name && (
        <>
          <p style={{ color: '#fff', fontWeight: 'bold', margin: '1rem auto 0.5rem' }}>
            Share event:
          </p>
          <SocialSharing>
            <LinkedinShareButton url={shareUrl}>
              <LinkedinIcon />
            </LinkedinShareButton>
            <TwitterShareButton url={shareUrl}>
              <TwitterIcon />
            </TwitterShareButton>
          </SocialSharing>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={() => handleButtonClick()}
              whileTap={{ scale: 0.95 }}
              type="button"
              width="9.25rem"
              style={{ alignSelf: 'center', marginTop: '0.5rem' }}>
              Continue
            </Button>
          </div>
        </>
      )}
    </FormContainer>
  );
};

const SocialSharing = styled.div`
  display: grid;
  gap: 0.875rem;
  grid-template-columns: repeat(2, 2.5rem);
  /* grid-template-rows: repeat(2, auto-fit); */
  margin: 0 auto 1.25rem;
  text-align: center;

  p {
    color: #000;
  }

  .react-share__ShareButton {
    border-radius: 50% !important;
    height: 2.5rem;
    width: 2.5rem;
  }

  svg {
    border-radius: 50% !important;
    height: 100%;
    width: 100%;
  }
`;

const Status = styled.h3`
  align-items: center;
  color: #fff;
  display: flex;
  font-family: 'Uni Neue', sans-serif;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  justify-content: center;
  line-height: 1.275em;
  text-align: center;

  ${({ newUserEmailVerified }) =>
    !newUserEmailVerified &&
    css`
      &:after {
        animation: dots 1s steps(5, end) infinite;
        content: ' .';
        margin-left: 0.25rem;
      }

      @keyframes dots {
        0%,
        20% {
          color: rgba(0, 0, 0, 0);
          text-shadow: 0.5rem 0 0 rgba(0, 0, 0, 0), 1rem 0 0 rgba(0, 0, 0, 0);
        }
        40% {
          color: #fff;
          text-shadow: 0.5rem 0 0 rgba(0, 0, 0, 0), 1rem 0 0 rgba(0, 0, 0, 0);
        }
        60% {
          text-shadow: 0.5rem 0 0 #fff, 1rem 0 0 rgba(0, 0, 0, 0);
        }
        80%,
        100% {
          text-shadow: 0.5rem 0 0 #fff, 1rem 0 0 #fff;
        }
      }
    `}
`;

export default NewUserEmailVerification;
