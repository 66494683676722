import { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { onAuthStateChanged } from 'firebase/auth';
import {
  AdminFactory,
  ModeratorDashboardFactory,
  ChatFactory,
  EmailHandlerFactory,
  EmailFactory,
  EmojiFactory,
  InteractionFactory,
  LivestreamFactory,
  ModerationFactory,
  ParticipantFactory,
  PollFactory,
  QAndAFactory,
  RegistrationFactory,
  UserProfileFactory
} from 'firebase';
import getFirebaseInstance from './firebase';
import firebaseConfig from './config';

function useAuth() {
  const [user, setUser] = useState(null);
  const [firebase, setFirebase] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let userUnsubscribe;
    const app = initializeApp(firebaseConfig);
    const firebaseInstance = getFirebaseInstance(app);

    firebaseInstance.admin = AdminFactory(firebaseInstance);
    firebaseInstance.moderatorDashboard = ModeratorDashboardFactory(firebaseInstance);
    firebaseInstance.emailHandler = EmailHandlerFactory(firebaseInstance);
    firebaseInstance.emails = EmailFactory(firebaseInstance);
    firebaseInstance.interaction = InteractionFactory(firebaseInstance);
    firebaseInstance.interaction.chat = ChatFactory(firebaseInstance);
    firebaseInstance.interaction.emojis = EmojiFactory(firebaseInstance);
    firebaseInstance.interaction.participants = ParticipantFactory(firebaseInstance);
    firebaseInstance.interaction.polls = PollFactory(firebaseInstance);
    firebaseInstance.interaction.qAndA = QAndAFactory(firebaseInstance);
    firebaseInstance.livestream = LivestreamFactory(firebaseInstance);
    firebaseInstance.moderation = ModerationFactory(firebaseInstance);
    firebaseInstance.registration = RegistrationFactory(firebaseInstance);
    firebaseInstance.userProfile = UserProfileFactory(firebaseInstance);

    setFirebase(firebaseInstance);
    
    const unsubscribe = onAuthStateChanged(firebaseInstance.auth, (userAuthInfo) => {
      if (userAuthInfo) {
        userUnsubscribe = firebaseInstance.subscribeToUserUpdates({
          uid: userAuthInfo.uid,
          snapshot: (_user) => {
            firebaseInstance.auth.currentUser
              .getIdTokenResult(true)
              .then(async (token) => {
                if (_user.exists) {
                  const userDatabaseInfo = _user.data();
                  setUser({
                    ...userAuthInfo,
                    ...userDatabaseInfo,
                    isAdmin: token.claims.isAdmin,
                    isModerator: token.claims.isModerator
                  });
                }
              })
              .catch((error) => {
                console.error(error.message);
              });
          }
        });
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }

      if (userUnsubscribe) {
        userUnsubscribe();
      }
    };
  }, []);

  return { user, setUser, firebase, loading };
}

export default useAuth;
