import React, { createContext, useState, useEffect, useRef } from 'react';
import { useWindowSize, useLocalStorage } from 'hooks';

const LocalContext = createContext();

const theme = {
  primary: '#002a3a',
  secondary: '#01788C',
  tertiary: '#78BE20'
};

const fontSizes = {
  default: '16px',
  medium: '18px',
  large: '20px'
};

const LocalContextProvider = (props) => {
  const [fontSizing, setFontSizing] = useLocalStorage(
    'innovation-exchange-forum-fontSize',
    fontSizes.default
  );
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedPollDocumentation, setSelectedPollDocumentation] = useState('general');
  const [aNewUserHasRegisteredForAnEvent, setANewUserHasRegisteredForAnEvent] = useState(false);
  const [showUserAccountDeletedModal, setShowUserAccountDeletedModal] = useState(false);
  const [showCookieBarModal, setShowCookieBarModal] = useState(false);
  const [registeredRecentlyWithOneStep, setRegisteredRecentlyWithOneStep] = useState(false);
  const [browserTabIsActive, setBrowserTabIsActive] = useState(true);
  const [navOpen, setNavOpen] = useState(false);
  const { windowWidth } = useWindowSize();
  const [isMobile, setIsMobile] = useState(true);
  const registerRef = useRef(null);
  const galleryRef = useRef(null);
  const contactRef = useRef(null);
  const faqRef = useRef(null);
  const streamRef = useRef(null);
  const headerRef = useRef(null);
  const speakersRef = useRef(null);

  const scrollToRef = (ref) => {
    console.log(ref);
    return ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  };

  useEffect(() => {
    if (windowWidth >= 1150) {
      setIsMobile(false);
    } else setIsMobile(true);
  }, [isMobile, windowWidth]);

  const handleBrowserTabActivity = (forcedFlag) => {
    if (typeof forcedFlag === 'boolean') {
      return forcedFlag ? setBrowserTabIsActive(true) : setBrowserTabIsActive(false);
    }
    return document.hidden ? setBrowserTabIsActive(false) : setBrowserTabIsActive(true);
  };

  useEffect(() => {
    const handleBrowserTabActivityFalse = () => handleBrowserTabActivity(false);
    const handleBrowserTabActivityTrue = () => handleBrowserTabActivity(true);

    document.addEventListener('visibilitychange', handleBrowserTabActivity);
    document.addEventListener('blur', handleBrowserTabActivityFalse);
    window.addEventListener('blur', handleBrowserTabActivityFalse);
    window.addEventListener('focus', handleBrowserTabActivityTrue);
    document.addEventListener('focus', handleBrowserTabActivityTrue);

    return () => {
      window.removeEventListener('blur', handleBrowserTabActivity);
      document.removeEventListener('blur', handleBrowserTabActivityFalse);
      window.removeEventListener('focus', handleBrowserTabActivityFalse);
      document.removeEventListener('focus', handleBrowserTabActivityTrue);
      document.removeEventListener('visibilitychange', handleBrowserTabActivityTrue);
    };
  }, []);

  const toggleFontSizing = (fontSize) => {
    if (fontSize === fontSizes.large) setFontSizing(fontSizes.large);
    else if (fontSize === fontSizes.medium) setFontSizing(fontSizes.medium);
    else setFontSizing(fontSizes.default);
  };

  return (
    <LocalContext.Provider
      value={{
        theme,
        isMobile,
        fontSizing,
        toggleFont: toggleFontSizing,
        galleryRef,
        registerRef,
        headerRef,
        speakersRef,
        contactRef,
        faqRef,
        scrollTo: scrollToRef,
        streamRef,
        selectedEvent,
        setSelectedEvent,
        aNewUserHasRegisteredForAnEvent,
        setANewUserHasRegisteredForAnEvent,
        showUserAccountDeletedModal,
        setShowUserAccountDeletedModal,
        showCookieBarModal,
        setShowCookieBarModal,
        browserTabIsActive,
        selectedPollDocumentation,
        setSelectedPollDocumentation,
        navOpen,
        setNavOpen,
        registeredRecentlyWithOneStep,
        setRegisteredRecentlyWithOneStep
      }}>
      {props.children}
    </LocalContext.Provider>
  );
};

export { LocalContextProvider };
export default LocalContext;
