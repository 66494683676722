import { httpsCallable } from 'firebase/functions';

function EmailFactory(firebase) {
  const sendSignInWithMagicLinkEmail = async ({
    firstName,
    email,
    eventName,
    actionCodeSettings
  }) => {
    const sendSignInWithEmailLinkEmailCallable = httpsCallable(
      firebase.functions,
      'emails-send-signInWithMagicLinkEmail'
    );
    return sendSignInWithEmailLinkEmailCallable({
      firstName,
      eventName,
      email: email.toLowerCase().trim(),
      actionCodeSettings
    });
  };

  const sendEventReminderEmail = async ({ event, templateAlias }) => {
    const sendEventReminderEmailCallable = httpsCallable(
      firebase.functions,
      'emails-send-eventReminder'
    );
    return sendEventReminderEmailCallable({ event, templateAlias });
  };

  const sendEventCancellationEmail = async ({ event }) => {
    const sendEventCancellationEmailCallable = httpsCallable(
      firebase.functions,
      'emails-send-eventCancellation'
    );
    return sendEventCancellationEmailCallable({ event });
  };

  return {
    sendSignInWithMagicLinkEmail,
    sendEventReminderEmail,
    sendEventCancellationEmail
  };
}

export default EmailFactory;
