import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { LocalContext } from 'context';
import { H5, P, breakpoints } from 'styles';
import { RenderWhenReady, Modal, SpeakerPopup } from 'components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Markup } from 'interweave';
import { useWindowSize } from 'hooks';
import { hexToRGB } from 'utils';

const Agendas = ({ agendas, colors, speakers }) => {
  const { agendaRef, theme } = useContext(LocalContext);
  const { windowWidth } = useWindowSize();
  const [selected, setSelected] = useState(agendas[0]);
  const { slots } = selected;

  return (
    <AgendaList id="agenda">
      <div ref={agendaRef} style={{ position: 'absolute', top: '-10rem' }} />
      <RenderWhenReady>
        <Container>
          <GridTitle $colors={colors} $theme={theme}>
            Agenda
          </GridTitle>
          <Agenda $colors={colors} $theme={theme}>
            <SectionTitle colors={colors}>Live Stream:</SectionTitle>
            {slots.map((slot) => (
              <Grid key={slot.orderNumber} $colors={colors}>
                <Name $colors={colors}>
                  <Markup content={slot.title} />
                  <Markup content={slot.speaker} />
                  <Summary>
                    <Markup content={slot.summary} />
                  </Summary>
                </Name>
                <SpeakerImages large={slot.speakerImages.length > 1}>
                  {/* {slot.speakerImages.length > 1 ? (
                    slot.speakerImages.sort((a, b) => a.imageOrder - b.imageOrder).map((speakerImage, index) => (
                      <Modal
                        trigger={
                          <ImgContainer key={index}>
                            <SpeakerImg image={getImage(speakerImage.image)} alt="speaker" />
                          </ImgContainer>
                        }
                        modalContent={<SpeakerPopup startPoint={index + 1} speakers={speakers} colors={colors} />}
                      />
                    ))
                  ) : (
                    <ImgContainer>
                      <SpeakerImg image={getImage(slot.speakerImages[0].image)} alt="speaker-1" />
                    </ImgContainer>
                  )} */}
                  {slot.speakerImages.map((speakerImage, index) => (
                    <Modal
                      trigger={
                        <ImgContainer key={index}>
                          <SpeakerImg image={getImage(speakerImage.image)} alt="speaker" />
                        </ImgContainer>
                      }
                      modalContent={
                        <SpeakerPopup
                          startPoint={speakerImage.imageOrder - 1}
                          speakers={speakers}
                          colors={colors}
                        />
                      }
                    />
                  ))}
                </SpeakerImages>
              </Grid>
            ))}
          </Agenda>
        </Container>
      </RenderWhenReady>
    </AgendaList>
  );
};

const GridTitle = styled(H5)`
  color: ${({ $colors }) => $colors.tertiary};
  grid-column: 2/6;
  ${breakpoints.sm} {
    margin-left: 1.5rem;
    grid-column: 2/12;
  }
  ${breakpoints.lg} {
    font-size: 38px;
    margin-bottom: 0;
  }
  ${breakpoints.xl} {
    grid-column: 3/11;
  }
`;

const SectionTitle = styled(P)`
  color: ${({ colors }) => colors.tertiary};
  font-size: 1.125rem;
  font-weight: 700;
  grid-column: 1/7;
  ${breakpoints.sm} {
    grid-column: 2/12;
    padding: 0.5rem 0;
  }
  ${breakpoints.xl} {
    grid-column: 3/11;
  }
`;

const Name = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  grid-column: 1/7;
  h5 {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1;
    margin-left: 1.25rem;
    position: relative;
    &:before {
      color: ${({ $colors }) => $colors.tertiary};
      content: '▶︎';
      display: block;
      font-size: 0.875rem;
      margin-left: -1.25rem;
      padding-top: 0.125rem;
      position: absolute;
    }
  }
  p,
  ul {
    font-size: 1rem;
    font-weight: 350;
    line-height: 1.25rem;
    list-style-type: none;
    margin-left: 1.25rem;
    li {
      margin-left: 1.25rem;
      position: relative;
      &:before {
        color: ${({ $colors }) => $colors.tertiary};
        content: '▶︎';
        display: block;
        font-size: 0.5rem;
        height: 1rem;
        margin-left: -1.25rem;
        position: absolute;
        vertical-align: baseline;
        width: 1.25rem;
      }
    }
  }
  ${breakpoints.sm} {
    grid-column: 1/8;
    h5 {
      font-size: 1.75rem;
      margin-left: 1.75rem;
      &:before {
        margin-left: -1.75rem;
        font-size: 1.25rem;
        padding-top: 0.25rem;
      }
    }
    p,
    ul {
      font-size: 1.5rem;
      line-height: 2rem;
      margin-left: 1.75rem;
      li {
        margin-left: 1.75rem;
        &:before {
          font-size: 1rem;
          margin-left: -1.75rem;
        }
      }
    }
  }
  ${breakpoints.lg} {
    /* grid-column: 1/7; */
    p,
    ul {
      margin-left: 2.5rem;
      li {
        margin-left: 1.5rem;
        &:before {
          margin-left: -1.5rem;
        }
      }
    }
    h5 {
      font-size: 2.25rem;
      margin-left: 2.5rem;
      &:before {
        font-size: 1.75rem;
        margin-left: -2.5rem;
      }
    }
  }
`;

const Summary = styled.p`
  font-size: 1.125rem !important;
  line-height: unset !important;
`;

const SpeakerImages = styled.div`
  align-self: start;
  display: flex;
  gap: 0.5rem;
  grid-column: 1/ 7;
  height: 100%;
  justify-self: center;
  position: relative;
  width: auto;
  ${breakpoints.sm} {
    grid-column: ${({ large }) => (large ? '1 / 11 ' : '8/11')};
    justify-self: ${({ large }) => (large ? 'center' : 'end')};
    margin-top: 0;
    min-height: 80px;
  }
  ${breakpoints.lg} {
    grid-column: 9/11;
    justify-self: end;
  }
  ${breakpoints.xl} {
    grid-column: 9/11;
  }
`;

const ImgContainer = styled.div`
  background: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
  filter: none;
  height: 100px;
  overflow: hidden;
  position: relative;
  width: 100px;
  ${breakpoints.sm} {
    height: 145px;
    width: 145px;
  }
`;

const SpeakerImg = styled(GatsbyImage)`
  height: 100%;
  width: 100%;
`;

const Grid = styled.div`
  display: grid;
  grid-column: 1/7;
  grid-gap: 1rem;
  grid-template-columns: repeat(6, 1fr);
  padding: 1.25rem 0;
  position: relative;
  &:not(:last-of-type) {
    &:before {
      background-color: ${({ $colors }) => $colors.tertiary};
      bottom: 0;
      content: '';
      height: 2px;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }

  ${breakpoints.sm} {
    grid-column: 2/12;
    grid-gap: 2rem;
    grid-template-columns: repeat(10, 1fr);
  }
  ${breakpoints.xl} {
    grid-column: 3/11;
  }
`;

const Agenda = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${Name} {
    color: #3c3c3c;
  }
  ${breakpoints.sm} {
    margin: 0 1.5rem;
    width: auto;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  height: auto;
  justify-content: flex-start;
  row-gap: 2rem;
  width: 100%;
  ${breakpoints.lg} {
    grid-column: 2/12;
  }
`;

const AgendaList = styled.div`
  background-color: #ededed;
  display: grid;
  grid-column: 1/13;
  grid-column-gap: 1rem;
  grid-template-columns: repeat(6, 1fr);
  height: auto;
  padding: 25rem 0 3rem 1rem;
  position: relative;
  width: 100%;
  z-index: 0;
  ${breakpoints.lg} {
    grid-column-gap: 3rem;
    grid-template-columns: repeat(12, 1fr);
    margin: 0 auto;
    padding: 10rem 1rem 5rem 1rem;
  }
`;

export default Agendas;
