/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Layout } from 'components';
import { AnimatePresence } from 'framer-motion';
import queryString from 'query-string';
import { LocalContextProvider } from './src/context/LocalContext';

const transitionDelay = 500;

export const wrapPageElement = ({ props }) => {
  const { component: PageComponent, page } = props.pageResources;
  return page.path === '/ie-not-supported/' ? (
    <PageComponent {...props} />
  ) : (
    <AnimatePresence exitBeforeEnter>
      <Layout {...props}>
        <PageComponent {...props} />
      </Layout>
    </AnimatePresence>
  );
};

export const wrapRootElement = ({ element }) => (
  <LocalContextProvider>{element}</LocalContextProvider>
);

export const onClientEntry = () => {
  const parseUrl = (params) => {
    const queryParams = queryString.parse(params);
    /* eslint-disable camelcase */
    let { utm_source, utm_medium } = queryParams;

    if (utm_source) {
      utm_source = utm_source
        .split('_')
        .map((word) => word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    }

    if (utm_medium) {
      utm_medium = utm_medium
        .split('_')
        .map((word) => word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    }

    return utm_source && utm_medium
      ? `${utm_source} - ${utm_medium}`
      : utm_source && !utm_medium
      ? utm_source
      : !utm_source && utm_medium
      ? utm_medium
      : document.referrer;
  };

  const referringUrl = JSON.parse(window.localStorage.getItem('referringUrlInnovationForum'))?.data;

  if (!referringUrl) {
    window.localStorage.setItem(
      'referringUrlInnovationForum',
      JSON.stringify({
        data: document.referrer ? parseUrl(window.location.search) : 'Direct Navigation'
      })
    );
  }
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location && location.state)
    location.state.referrer = prevLocation ? prevLocation.pathname : null;
};

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
  } else {
    const savedPosition = getSavedScrollPosition(location);
    window.setTimeout(() => window.scrollTo(...(savedPosition || [0, 0])), transitionDelay);
  }
  return false;
};
