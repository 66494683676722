import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { FirebaseContext } from 'context';
import { Markup } from 'interweave';

const EmailNotVerifiedNotification = ({
  colors,
  returningUserHasNotConfirmedTheirEmailAddressYet,
  newUserLocalStorageData
}) => {
  const { firebase } = useContext(FirebaseContext);

  useEffect(() => {
    if (firebase && returningUserHasNotConfirmedTheirEmailAddressYet) {
      const { firstName, email, actionCodeSettings } = newUserLocalStorageData;
      firebase.emails.sendSignInWithMagicLinkEmail({
        firstName,
        email,
        eventName: 'The Skillnet Innovation Exchange LIVE',
        actionCodeSettings
      });
    }
  }, [firebase, returningUserHasNotConfirmedTheirEmailAddressYet]);

  return (
    <Wrapper colors={colors}>
      <Markup content="Please click the link in the email we just sent you to complete your registration application.<br /> Can't find our email? Be sure to check your junk!" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  align-items: center;
  animation: slideIn 750ms forwards;
  background-color: ${({ colors }) => colors.tertiary};
  bottom: -4.188rem;
  color: #fff;
  display: flex;
  font-family: 'Uni Neue', sans-serif;
  font-weight: bold;
  height: auto;
  justify-content: center;
  left: 0;
  letter-spacing: 0.05rem;
  line-height: 1.325rem;
  opacity: 0;
  padding: 0.75rem 0.75rem 0.813rem;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 11;

  @keyframes slideIn {
    0% {
      bottom: -4.188rem;
      opacity: 0;
    }

    100% {
      bottom: 0;
      opacity: 1;
    }
  }
`;

export default EmailNotVerifiedNotification;
