import { collection, getDocs, limit, query, where } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';

function AdminFactory(firebase) {
  const fetchAllFirebaseAuthUserData = async () => {
    const fetchAllUsersCallable = httpsCallable(
      firebase.functions,
      'admin-reports-fetchAllFirebaseAuthUserData'
    );
    return fetchAllUsersCallable();
  };

  const getUserFromDatabaseWithUID = async ({ uid }) =>
    getDocs(query(collection(firebase.fsdb, 'users'), where('uid', '==', uid), limit(1)));

  const grantAdminRole = async ({ uid }) => {
    const grantAdminRoleCallable = httpsCallable(firebase.functions, 'admin-roles-admin-grant');
    return grantAdminRoleCallable({ uid });
  };

  const grantModeratorRole = async ({ uid, eid }) => {
    const grantModeratorRoleCallable = httpsCallable(
      firebase.functions,
      'admin-roles-moderator-grant'
    );
    return grantModeratorRoleCallable({ uid, eid });
  };

  const removeModeratorRole = async ({ uid, eid }) => {
    const removeModeratorRoleCallable = httpsCallable(
      firebase.functions,
      'admin-roles-moderator-remove'
    );
    return removeModeratorRoleCallable({ uid, eid });
  };

  const addNewFieldsToAllUserDocs = async () => {
    const addNewFieldsToAllUserDocsCallable = httpsCallable(
      firebase.functions,
      'user-account-addNewFieldsToAllUserDocs'
    );
    return addNewFieldsToAllUserDocsCallable();
  };

  return {
    fetchAllFirebaseAuthUserData,
    getUserFromDatabaseWithUID,
    grantAdminRole,
    grantModeratorRole,
    removeModeratorRole,
    addNewFieldsToAllUserDocs
  };
}

export default AdminFactory;
