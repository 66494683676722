import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import BaseSelect from 'react-select';
import { FirebaseContext } from 'context';
import { LoadingSpinner } from 'components';
import FixRequiredSelect from 'components/Forms/FixRequiredSelect';

function FormSelect(props) {
  return <FixRequiredSelect {...props} SelectComponent={BaseSelect} options={props.options} />;
}

const reminders = [
  {
    label: '48-Hours Before Reminder',
    value: 'reminder-email-48-hours-before'
  },
  {
    label: '24-Hours Before Reminder',
    value: 'reminder-email-24-hours-before'
  },
  {
    label: 'Same Day Reminder',
    value: 'reminder-email-same-day'
  },
  {
    label: 'Event Cancelled',
    value: 'event-cancelled-email'
  }
];

function Emails({ events, customDropdownMenuStyles }) {
  const { firebase } = useContext(FirebaseContext);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedReminder, setSelectedReminder] = useState(null);
  const [formIncomplete, setFormIncomplete] = useState(true);
  const [sendingEmail, setSendingEmail] = useState(false);

  console.log(selectedEvent, selectedReminder);

  const clearFields = () => {
    setSelectedEvent(null);
    setSelectedReminder(null);
  };

  const handleSendEventReminderEmail = async () => {
    try {
      setSendingEmail(true);
      await firebase.emails.sendEventReminderEmail({
        event: selectedEvent,
        templateAlias: selectedReminder.value
      });
    } catch (error) {
      console.error(error);
    } finally {
      setSendingEmail(false);
      clearFields();
    }
  };

  const handleSendEventCancellationEmail = async () => {
    try {
      setSendingEmail(true);
      await firebase.emails.sendEventCancellationEmail({ event: selectedEvent });
    } catch (error) {
      console.error(error);
    } finally {
      setSendingEmail(false);
      clearFields();
    }
  };

  useEffect(() => {
    if (selectedEvent && selectedReminder) {
      setFormIncomplete(false);
    } else {
      setFormIncomplete(true);
    }
  }, [selectedEvent, selectedReminder]);

  return (
    <Container>
      <Title>Emails</Title>
      <Card>
        <div>
          <Label>Event</Label>
          <FormSelect
            value={events.filter(
              ({ value }) => value === `Event ${selectedEvent?.eid} - ${selectedEvent?.name}`
            )}
            onChange={setSelectedEvent}
            placeholder="Select"
            styles={customDropdownMenuStyles()}
            options={events}
          />
        </div>
        <div>
          <Label>Email Type</Label>
          <FormSelect
            value={selectedReminder}
            onChange={setSelectedReminder}
            placeholder="Select"
            styles={customDropdownMenuStyles()}
            options={reminders}
          />
        </div>
        <AnimatePresence>
          {selectedReminder && selectedReminder.label === 'Event Cancelled' ? (
            <Button
              type="button"
              disabled={formIncomplete}
              invert
              onClick={() => handleSendEventCancellationEmail()}
              whileHover={!formIncomplete && { scale: 1.0125 }}
              whileTap={
                !formIncomplete && {
                  scale: 0.95
                }
              }>
              {sendingEmail ? (
                <LoadingSpinner style={{ width: '1.25rem', color: '#fff' }} />
              ) : (
                <span>Send Event Cancellation Email</span>
              )}
            </Button>
          ) : (
            <Button
              type="button"
              disabled={formIncomplete}
              onClick={() => handleSendEventReminderEmail()}
              whileHover={!formIncomplete && { scale: 1.0125 }}
              whileTap={
                !formIncomplete && {
                  scale: 0.95
                }
              }>
              {sendingEmail ? (
                <LoadingSpinner style={{ width: '1.25rem', color: '#fff' }} />
              ) : (
                <span>Send Event Reminder</span>
              )}
            </Button>
          )}
        </AnimatePresence>
      </Card>
    </Container>
  );
}

const Container = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
  justify-content: center;
  position: relative;
  width: 100%;
`;

const Title = styled.h3`
  color: #004d9a;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.75rem;
  text-align: center;
  width: 100%;
`;

const Card = styled.div`
  background: #ffffff;
  border-radius: 2px;
  box-shadow: 0px 6px 20px rgba(196, 196, 196, 0.4);
  display: flex;
  flex-direction: column;
  font-weight: 300;
  gap: 1.75rem;
  max-width: 575px;
  padding: 3.875rem 1.75rem 2.75rem 1.75rem;
  position: relative;
  width: 100%;
`;

const Label = styled.label`
  align-items: center;
  color: #004d9a;
  display: flex;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.125rem;
`;

const Button = styled(motion.button)`
  align-items: center;
  background-color: ${({ disabled, invert }) =>
    disabled ? '#c4c4c4' : invert ? '#F55151' : '#004d9a'};
  border-radius: 3rem;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  justify-content: center;
  margin-left: auto;
  min-width: 240px;
  padding: 1.125rem 2rem;
  text-transform: uppercase;
  transition: background-color 200ms ease-in-out;
  width: max-content;
  :disabled {
    cursor: default;
  }
`;

export default Emails;
