import { arrayUnion, doc, setDoc } from 'firebase/firestore';

function LivestreamFactory(firebase) {
  const updateVideoSessionData = async ({ eid, uid, timeRange }) =>
    setDoc(
      doc(firebase.fsdb, 'users', uid, 'videoSessionData', eid),
      {
        eid,
        timeRanges: arrayUnion(...timeRange)
      },
      { merge: true }
    );

  return {
    updateVideoSessionData
  };
}
export default LivestreamFactory;
