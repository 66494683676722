import React, { useEffect, useState, useContext } from 'react';
import { Link, navigate } from 'gatsby';
import styled from 'styled-components';
import { LocalContext, FirebaseContext } from 'context';
import { useLocation } from '@reach/router';
import { breakpoints } from 'styles';
import { scrollToAnchor } from 'utils';
import { motion } from 'framer-motion';

const EventNavbar = ({ eventDate, eventSlug, eid, colors }) => {
  const { theme, isMobile } = useContext(LocalContext);
  const { user } = useContext(FirebaseContext);
  const { pathname } = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeLink, setActiveLink] = useState(pathname);

  useEffect(() => setActiveLink(pathname), [pathname]);

  const handleScroll = () => {
    const scrollNavTop = window.scrollY;
    if (scrollNavTop >= 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const toggleActiveLink = (link) => {
    setActiveLink(link);
  };

  return (
    <Wrapper
      colors={colors}
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      scrolled={isScrolled}
      theme={theme}>
      <DateContainer>
        <h3
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: eventDate
          }}
        />
      </DateContainer>
      {user?.eventsUserCanAccess.includes(eid) && (
        <NavLinks
          initial={isMobile ? { opacity: 0, y: -50 } : { opacity: 1, y: 0 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.75 }}>
          <NavLink
            colors={colors}
            to={`/events/${eventSlug}/livestream/#livestream`}
            className={activeLink === '/livestream' ? 'active' : null}
            onClick={(e) => {
              if (pathname === `/events/${eventSlug}/livestream`) {
                e.preventDefault();
                scrollToAnchor('livestream', {
                  duration: 1200,
                  offset: -160
                });
                toggleActiveLink('/livestream');
              } else {
                e.preventDefault();
                navigate(`/events/${eventSlug}/`);
                scrollToAnchor('livestream', {
                  duration: 1200,
                  offset: -160
                });
              }
            }}>
            Live stream
          </NavLink>
          <NavLink
            colors={colors}
            to={`/events/${eventSlug}/livestream/#agenda`}
            className={activeLink === '/agenda' ? 'active' : null}
            onClick={(e) => {
              if (pathname === `/events/${eventSlug}/livestream`) {
                e.preventDefault();
                scrollToAnchor('agenda', {
                  duration: 1200,
                  offset: -120
                });
                toggleActiveLink('/agenda');
              } else {
                e.preventDefault();
                navigate(`/events/${eventSlug}/`);
                scrollToAnchor('agenda', {
                  duration: 1200,
                  offset: -210
                });
              }
            }}>
            Agenda
          </NavLink>
          {/* <NavLink
            colors={colors}
            to={`/events/${eventSlug}/livestream/#speakers`}
            className={activeLink === '/speakers' ? 'active' : null}
            onClick={(e) => {
              if (pathname === `/events/${eventSlug}/livestream`) {
                e.preventDefault();
                scrollToAnchor('speakers', {
                  duration: 1200,
                  offset: -180
                });
                toggleActiveLink('/speakers');
              } else {
                e.preventDefault();
                navigate(`/events/${eventSlug}/`);
                scrollToAnchor('speakers', {
                  duration: 1200,
                  offset: -210
                });
              }
            }}>
            Speakers
          </NavLink> */}
        </NavLinks>
      )}
    </Wrapper>
  );
};

const NavLink = styled(Link)`
  align-items: center;
  color: white;
  cursor: pointer;
  display: flex;
  font-family: 'Uni Neue', sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  justify-content: center;
  letter-spacing: 0.5px;
  line-height: 1.25rem;
  margin-left: 5rem;
  padding-top: 0.125rem;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  width: 100%;
  ${breakpoints.lg} {
    color: white;
    font-size: 0.875rem;
    line-height: 1.5rem;
    text-decoration: none;
    text-transform: uppercase;
  }
  &:hover {
    text-decoration: underline;
    text-underline-position: under;
  }
  &.active {
    text-decoration: underline;
    text-underline-position: under;
  }
`;

const NavLinks = styled(motion.div)`
  align-items: center;
  background: transparent;
  box-shadow: 0px 0.185rem 0.75rem rgba(0, 0, 0, 0.25);
  color: white;
  display: flex;
  flex-direction: column;
  grid-area: links;
  grid-gap: 0px;
  height: auto;
  justify-content: center;
  ${breakpoints.lg} {
    background: transparent;
    box-shadow: none;
    flex-direction: row;
    grid-column: 8/12;
    height: 100%;
    justify-content: flex-end;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0px;
  }
  ${breakpoints.xl} {
    grid-column: 9/12;
  }
`;

const DateContainer = styled.div`
  cursor: default;
  grid-area: date;
  grid-column: 2/8;
  height: auto;
  margin: auto 0;
  h3 {
    color: white;
    font-family: 'droid-sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.25rem;
    margin: 0;
    text-align: left;
    text-transform: uppercase;
  }
  ${breakpoints.lg} {
    grid-column: 2/8;
    height: auto;
  }
`;

const Wrapper = styled(motion.nav)`
  background: 'white;';
  background: ${({ colors }) => colors.tertiary};
  box-shadow: none;
  box-shadow: 0px 0.185rem 0.75rem rgba(0, 0, 0, 0.15);
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.15);
  display: none;
  grid-template-columns: repeat(12, 1fr);
  height: 3.5rem;
  position: fixed;
  top: 4.8rem;
  transition-duration: 0.75s;
  width: 100%;
  z-index: 3;
  ${breakpoints.lg} {
    align-items: center;
    display: grid;
    grid-template-areas: 'date links';
  }
`;

export default EventNavbar;
