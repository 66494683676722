import React, { useContext } from 'react';
import styled from 'styled-components';
import { LocalContext } from 'context';
import { StaticImage } from 'gatsby-plugin-image';

const HubHeaderBackground = ({ colors }) => {
  const { theme } = useContext(LocalContext);

  return (
    <Background $theme={theme} colors={colors}>
      <StaticImage
        src="../../../../assets/images/event-2/banner.png"
        formats={['auto', 'webp', 'avif']}
        placeholder="blurred"
        alt="Background Image"
        quality={90}
      />
    </Background>
  );
};

const Background = styled.div`
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  .gatsby-image-wrapper {
    bottom: 0;
    display: block;
    height: 100%;
    min-width: 600px;
    object-fit: contain !important;
    position: absolute !important;
    right: 0;
  }
`;

export default HubHeaderBackground;
