import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FirebaseContext } from 'context';
import { LoadingSpinner } from 'components';

function Miscellaneous() {
  const { firebase } = useContext(FirebaseContext);
  const [retrieving, setRetrieving] = useState(false);
  const [invalidUids, setInvalidUids] = useState([]);

  const handleGetInvalidUIDs = async () => {
    try {
      setRetrieving(true);
      const { data: allUsers } = await firebase.admin.fetchAllFirebaseAuthUserData();

      const promises = [];
      const results = [];

      allUsers.forEach((_user) => {
        promises.push(
          firebase.admin.getUserFromDatabaseWithUID({ uid: _user.uid }).then((res) => {
            if (res.empty) {
              results.push(_user.uid);
            }
          })
        );
      });

      await Promise.all(promises);

      // eslint-disable-next-line no-console
      setInvalidUids(results);
    } catch (error) {
      console.error(error);
    } finally {
      setRetrieving(false);
    }
  };
  return (
    <Container>
      <Title>Miscellaneous</Title>
      <Card>
        <AnimatePresence>
          {invalidUids.length > 0 && (
            <>
              <Feedback>Invalid UIDs Found</Feedback>
              <List>
                {invalidUids.map((uid) => (
                  <li>{uid}</li>
                ))}
              </List>
            </>
          )}
        </AnimatePresence>
        <Button
          type="button"
          onClick={handleGetInvalidUIDs}
          whileHover={{ scale: 1.0125 }}
          whileTap={{
            scale: 0.95
          }}>
          {retrieving ? (
            <LoadingSpinner style={{ width: '1.25rem', color: '#fff' }} />
          ) : (
            <span>
              Get Invalid UID<small>s</small>
            </span>
          )}
        </Button>
      </Card>
    </Container>
  );
}

const Container = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
  justify-content: center;
  position: relative;
  width: 100%;
`;

const Title = styled.h3`
  color: #004d9a;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.75rem;
  text-align: center;
  width: 100%;
`;

const Card = styled.div`
  background: #ffffff;
  border-radius: 2px;
  box-shadow: 0px 6px 20px rgba(196, 196, 196, 0.4);
  display: flex;
  flex-direction: column;
  font-weight: 300;
  gap: 1.75rem;
  max-width: 575px;
  padding: 3.875rem 1.75rem 2.75rem 1.75rem;
  position: relative;
  width: 100%;
`;

const Feedback = styled.span`
  color: #f55151;
  font-size: 0.875rem;
  left: 1.75rem;
  position: absolute;
  top: 2.125rem;
`;

const List = styled(motion.ul)`
  color: #004d9a;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.125rem;
  li {
    list-style-type: none;
    margin-bottom: 0.5rem;
  }
`;

const Button = styled(motion.button)`
  align-items: center;
  background-color: ${({ disabled, invert }) =>
    disabled ? '#C4C4C4' : invert ? '#F55151' : '#004d9a'};
  border-radius: 3rem;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  justify-content: center;
  padding: 1.125rem;
  text-transform: uppercase;
  transition: background-color 200ms ease-in-out;
  width: 100%;
  :disabled {
    cursor: default;
  }
`;

export default Miscellaneous;
