import * as React from 'react';

function SvgImagePlaceholder(props) {
  return (
    <svg viewBox="0 0 639 395" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M501.425 222.351l130.21 130.272c15.687 15.695 4.706 42.377-17.78 42.377H25.035c-21.963 0-33.468-26.682-17.78-42.377l195.576-195.669c9.936-9.941 25.624-9.941 35.037 0l129.164 129.225c9.935 9.94 25.623 9.94 35.036 0l63.797-63.828c9.936-9.94 25.624-9.94 35.56 0zM417.756 0c-37.128 0-66.935 29.821-66.935 66.967s29.807 66.967 66.935 66.967 66.935-29.821 66.935-66.967S454.361 0 417.756 0z"
        fill="#C4C4C4"
        fillOpacity={0.3}
      />
    </svg>
  );
}

export default SvgImagePlaceholder;
