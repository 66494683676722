const firebaseConfig = {
  apiKey: 'AIzaSyA_uFbGySeEN0CQnFEYetlDCgIaJdqp6jg',
  authDomain: 'innovation-exhange-forum.firebaseapp.com',
  databaseURL: 'https://innovation-exhange-forum-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'innovation-exhange-forum',
  storageBucket: 'innovation-exhange-forum.appspot.com',
  messagingSenderId: '582459166813',
  appId: '1:582459166813:web:5f3d626ad873d66e923db4',
  measurementId: 'G-17PHL8LDT5'
};

export default firebaseConfig;
