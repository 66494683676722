import React, { useContext } from 'react';
import styled from 'styled-components';
import { FirebaseContext } from 'context';
import { AnimatePresence, motion } from 'framer-motion';
import { PinComment } from 'components/Events/Livestream/Interaction/Chat';
import { hexToRGB } from 'utils';
import { format } from 'date-fns';
import { Star } from 'assets/animated-svgs';

function Question({
  eid,
  colors,
  submittedQuestion,
  selectedQuestion,
  setSelectedQuestion,
  setDeleteQuestion,
  index,
  arr
}) {
  const { firebase, user } = useContext(FirebaseContext);

  const handleQuestionSelection = () => {
    if (user?.isModerator?.includes(eid)) {
      if (selectedQuestion?.qid === submittedQuestion.qid) {
        setSelectedQuestion(null);
      } else {
        setSelectedQuestion(submittedQuestion);
      }
    }
  };

  const handleStarQuestion = ({ e, uid, qid, isStarred }) => {
    e.stopPropagation();
    if (isStarred) {
      firebase.interaction.qAndA.unstarQuestion({ uid, qid });
    } else {
      firebase.interaction.qAndA.starQuestion({ uid, qid });
    }
  };
  return (
    <Wrapper
      layout="position"
      layoutDependency={index}
      colors={colors}
      isStarred={submittedQuestion?.starred?.status}
      isModerator={user?.isModerator?.includes(eid)}
      isSelected={selectedQuestion?.qid === submittedQuestion.qid}
      onClick={handleQuestionSelection}>
      <SingleQuestion>
        {user?.isModerator?.includes(eid) && (
          <SelectQuestionCheckbox
            checked={selectedQuestion?.qid === submittedQuestion.qid}
            id="selectQuestionCheckbox"
            name="selectQuestionCheckbox"
            colors={colors}
            type="checkbox"
            readOnly
          />
        )}
        <p>
          <span>
            {user?.uid === submittedQuestion.uid
              ? `My Question${arr.length > 1 ? ` (${arr.length - index})` : ''}`
              : submittedQuestion.name
              ? `${submittedQuestion.name}`
              : 'Anonymous User'}
          </span>
          {submittedQuestion.timestamp && (
            <span>{format(submittedQuestion.timestamp.toDate(), 'HH:mmaaa')}</span>
          )}
        </p>
        <p>{submittedQuestion.text}</p>
        {user?.isModerator?.includes(eid) && (
          <StarQuestion
            isStarred={submittedQuestion.starred.status}
            onClick={(e) =>
              handleStarQuestion({
                e,
                uid: submittedQuestion.uid,
                qid: submittedQuestion.qid,
                isStarred: submittedQuestion.starred.status
              })
            }>
            <Star
              stroke={submittedQuestion.starred.status ? colors.tertiary : '#000'}
              fill={submittedQuestion.starred.status ? colors.tertiary : 'transparent'}
            />
            {submittedQuestion.starred.status ? 'Unprioritise' : 'Prioritise'}
          </StarQuestion>
        )}
        {(user?.isModerator?.includes(eid) || user.uid === submittedQuestion.uid) && (
          <DeleteQuestion
            colors={colors}
            whileTap={{ scale: 0.9 }}
            onClick={() =>
              setDeleteQuestion({
                open: true,
                question: {
                  uid: submittedQuestion.uid,
                  qid: submittedQuestion.qid
                }
              })
            }>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path d="M160 400C160 408.8 152.8 416 144 416C135.2 416 128 408.8 128 400V192C128 183.2 135.2 176 144 176C152.8 176 160 183.2 160 192V400zM240 400C240 408.8 232.8 416 224 416C215.2 416 208 408.8 208 400V192C208 183.2 215.2 176 224 176C232.8 176 240 183.2 240 192V400zM320 400C320 408.8 312.8 416 304 416C295.2 416 288 408.8 288 400V192C288 183.2 295.2 176 304 176C312.8 176 320 183.2 320 192V400zM317.5 24.94L354.2 80H424C437.3 80 448 90.75 448 104C448 117.3 437.3 128 424 128H416V432C416 476.2 380.2 512 336 512H112C67.82 512 32 476.2 32 432V128H24C10.75 128 0 117.3 0 104C0 90.75 10.75 80 24 80H93.82L130.5 24.94C140.9 9.357 158.4 0 177.1 0H270.9C289.6 0 307.1 9.358 317.5 24.94H317.5zM151.5 80H296.5L277.5 51.56C276 49.34 273.5 48 270.9 48H177.1C174.5 48 171.1 49.34 170.5 51.56L151.5 80zM80 432C80 449.7 94.33 464 112 464H336C353.7 464 368 449.7 368 432V128H80V432z" />
            </svg>
          </DeleteQuestion>
        )}
      </SingleQuestion>
      <AnimatePresence initial={false}>
        {submittedQuestion.answer && (
          <ModeratorsAnswer
            colors={colors}
            isModerator={user?.isModerator?.includes(eid)}
            isSelected={selectedQuestion?.qid === submittedQuestion.qid}>
            <p>
              <span>Moderator&apos;s Answer:</span>
              {submittedQuestion.answer.timestamp && (
                <span>{format(submittedQuestion.answer.timestamp.toDate(), 'HH:mmaaa')}</span>
              )}
            </p>
            <p>{submittedQuestion.answer.text}</p>
          </ModeratorsAnswer>
        )}
      </AnimatePresence>
    </Wrapper>
  );
}

const Wrapper = styled(motion.div).attrs({
  initial: {
    opacity: 0,
    height: 0
  },
  animate: {
    opacity: 1,
    height: 'auto',
    transition: {
      type: 'tween',
      ease: 'easeInOut',
      height: {
        duration: 0.4
      },
      opacity: {
        duration: 0.65,
        delay: 0.1
      }
    }
  },
  exit: {
    opacity: 0,
    height: 0,
    transition: {
      type: 'tween',
      ease: 'easeInOut',
      height: {
        duration: 0.4,
        delay: 0.55
      },
      opacity: {
        duration: 0.65
      }
    }
  }
})`
  background-color: ${({ isSelected, colors }) =>
    isSelected ? hexToRGB({ color: colors.tertiary, alpha: 0.2 }) : 'transparent'};
  color: ${({ isSelected, colors }) => (isSelected ? colors.tertiary : '#000')};
  cursor: ${({ isModerator }) => (isModerator ? 'pointer' : 'auto')};
  font-size: 1rem;
  margin-bottom: 0.688em;
  padding: 0.125em 0.25em 0.25em;
  position: relative;
  transition: background-color 200ms ease-in-out, color 200ms ease-in-out;

  p {
    padding-left: ${({ isModerator }) => (isModerator ? '1.375rem' : 0)};
  }

  p:first-of-type {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.313em;

    span:first-of-type {
      font-weight: 700;
    }

    span:last-of-type {
      color: ${({ isSelected }) => (isSelected ? '#000' : '#c4c4c4')};
      transition: color 200ms ease-in-out;
      font-size: 0.75em;
    }
  }

  small {
    color: ${({ isStarred, colors }) => (isStarred ? colors.tertiary : '#000')};
    display: inline-block;
    font-size: 0.65rem;
    left: 0.95em;
    position: relative;
  }
`;

const SingleQuestion = styled.div`
  position: relative;
`;

export const SelectQuestionCheckbox = styled.input`
  appearance: none;
  background-color: transparent;
  border: 1px solid #bdbdbd;
  cursor: pointer;
  height: 1rem;
  margin-top: 2px;
  outline: none;
  position: absolute;
  transition: all 0.1s;
  width: 1rem;

  &:focus {
    border: 2px solid ${({ colors }) => colors.tertiary};
  }

  &:checked {
    border: 1px solid ${({ colors }) => colors.tertiary};
  }

  &:checked:before {
    color: ${({ colors }) => colors.tertiary};
    content: '✓';
    font-size: 0.8rem;
    height: 0.8rem;
    left: 50%;
    outline: none;
    position: absolute;
    top: 50%;
    transform: translate(-37%, -60%);
    width: 0.8rem;
  }
`;

const ModeratorsAnswer = styled(motion.div).attrs({
  initial: {
    opacity: 0,
    height: 0
  },
  animate: {
    opacity: 1,
    height: 'auto',
    transition: {
      type: 'tween',
      ease: 'easeInOut',
      height: {
        duration: 0.4
      },
      opacity: {
        duration: 0.65,
        delay: 0.1
      }
    }
  },
  exit: {
    opacity: 0,
    height: 0,
    transition: {
      type: 'tween',
      ease: 'easeInOut',
      height: {
        duration: 0.4,
        delay: 0.55
      },
      opacity: {
        duration: 0.65
      }
    }
  }
})`
  color: ${({ isSelected }) => (isSelected ? '#000' : '#c4c4c4')};
  cursor: ${({ isModerator }) => (isModerator ? 'pointer' : 'auto')};
  margin-top: 0.625rem;
  padding: 0.125rem 0 0.25rem 1rem;
  transition: color 200ms ease-in-out;
`;

const DeleteQuestion = styled(motion.button)`
  > svg {
    height: 12px;
    width: 12px;
    fill: #000000;
  }
  align-self: flex-end;
  background: none;
  bottom: 0.125em;
  cursor: pointer;
  margin-left: auto;
  position: absolute;
  right: 0.125em;
  &:hover {
    > svg {
      fill: ${({ colors }) => colors.secondary};
    }
  }
`;

const StarQuestion = styled(PinComment)`
  > svg {
    bottom: unset;
  }
`;

export default Question;
