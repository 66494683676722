import React, { useContext } from 'react';
import styled from 'styled-components';
import { P, breakpoints } from 'styles';
import { LocalContext } from 'context';

const Terms = () => {
  const { theme } = useContext(LocalContext);
  return (
    <SectionWrapper $theme={theme}>
      <svg style={{ position: 'absolute' }}>
        <clipPath id="curve" clipPathUnits="objectBoundingBox">
          <path d="M1,1 V0.136 C0.671,-0.036,0.337,-0.019,0,0.046 V1 H1" />
        </clipPath>
      </svg>
      <P>
        The Skillnet Innovation Exchange LIVE events are hosted by Get Broadcasting on behalf of
        Skillnet Innovation Exchange. Your name and email address are required for you to register
        and join the event. Once you provide your name and email address, Get Broadcasting will
        provide you with the event link so you can access the online event. Please see the Get
        Broadcasting{' '}
        <a href="https://www.getbroadcasting.com/privacy-policy/" target="_blank" rel="noreferrer">
          Privacy Notice
        </a>{' '}
        for more details on how Get Broadcasting manages your personal data. Get Broadcasting works
        with Skillnet Innovation Exchange to engage with SMEs on Skillnet Innovation Exchange&apos;s
        behalf. Your registration details are collected by Get Broadcasting. For further
        information, please refer to the Online Privacy Notice and the EEA HCP Privacy Notice. This
        data will not be used for additional purposes without your consent. Please see the Skillnet
        Innovation Exchange{' '}
        <a
          href="https://theinnovationexchange.ie/privacy-policy-2/"
          target="_blank"
          rel="noreferrer">
          Privacy Notice
        </a>{' '}
        for more details on how Skillnet Innovation Exchange manages personal data. By accepting
        this invitation from Skillnet Innovation Exchange for attendance at this Hybrid Event, you
        agree to the terms & conditions - click{' '}
        <a
          href="https://theinnovationexchange.ie/privacy-policy-2/"
          target="_blank"
          rel="noreferrer">
          here
        </a>{' '}
        for more details.
      </P>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  background-color: ${({ $theme }) => $theme.primary};
  background-size: cover;
  clip-path: url(#curve);
  padding: 3rem 1.75rem;
  width: 100%;
  p {
    color: #ffffff;
    font-family: 'Uni Neue', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.375rem;
    margin: 0;
    a {
      color: #ffffff;
      text-decoration: underline;
      text-decoration-thickness: from-font;
      text-underline-position: under;
    }
  }
  ${breakpoints.lg} {
    padding: 3rem 8rem;
  }
`;

export default Terms;
