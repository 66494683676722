import React, { useContext, useState, useEffect } from 'react';
import {
  AnswerPollModal,
  WordPollModal,
  FeedbackAnswerModal,
  LoadingSpinner,
  GeneralAnalyticsModal,
  FeedbackAnalyticsModal,
  WordCloudAnalyticsModal
} from 'components';
import styled from 'styled-components';
import { FirebaseContext, LocalContext } from 'context';
import { AnimatePresence, motion } from 'framer-motion';
import { fadeInAndOutVariants } from 'styles';

const UserPanel = () => {
  const { firebase, user } = useContext(FirebaseContext);
  const [arePollsLoading, setArePollsLoading] = useState(true);
  const [isAnswerPollModalOpen, setIsAnswerPollModalOpen] = useState(false);
  const [currentlyOpenPoll, setCurrentlyOpenPoll] = useState(false);
  const { selectedEvent } = useContext(LocalContext);
  const { eid } = selectedEvent;
  const [isAnalyticsModalOpen, setIsAnalyticsModalOpen] = useState(false);
  const [
    pollAnalyticsBeingCurrentlySharedWithUsers,
    setPollAnalyticsBeingCurrentlySharedWithUsers
  ] = useState(false);
  const [serverTimeOffset, setServerTimeOffset] = useState(null);

  const showNoOpenPollsMessage = () => {
    setIsAnswerPollModalOpen(false);
    setIsAnalyticsModalOpen(false);
    setPollAnalyticsBeingCurrentlySharedWithUsers(null);
    setArePollsLoading(false);
  };

  console.log(user);

  useEffect(() => {
    let unsubscribeToServerTimeOffset;
    let unsubscribeFromPolls;
    if (firebase && user) {
      unsubscribeToServerTimeOffset = firebase.interaction.polls.subscribeToServerTimeOffset({
        snapshot: (snapshot) => {
          if (snapshot.exists()) {
            setServerTimeOffset(snapshot.val());
          }
        }
      });
      unsubscribeFromPolls = firebase.interaction.polls.subscribeToPolls({
        eid,
        snapshot: (snapshot) => {
          if (snapshot.exists()) {
            const polls = [];

            snapshot.forEach((child) => {
              polls.push({
                pid: child.key,
                ...child.val()
              });
            });

            const _currentlyOpenPoll = polls.find((poll) => poll.isOpen);
            const _pollAnalyticsBeingCurrentlySharedWithUsers = polls.find(
              (poll) => poll.shareResults
            );

            if (_currentlyOpenPoll) {
              setCurrentlyOpenPoll(_currentlyOpenPoll);
              setIsAnswerPollModalOpen(true);
              setArePollsLoading(false);
            } else {
              showNoOpenPollsMessage();
            }

            if (_pollAnalyticsBeingCurrentlySharedWithUsers) {
              setPollAnalyticsBeingCurrentlySharedWithUsers(
                _pollAnalyticsBeingCurrentlySharedWithUsers
              );
              setIsAnalyticsModalOpen(true);
              setArePollsLoading(false);
            } else {
              setIsAnalyticsModalOpen(false);
              setPollAnalyticsBeingCurrentlySharedWithUsers(null);
            }
          } else if (!snapshot.exists()) {
            showNoOpenPollsMessage();
          }
        }
      });
    }
    if (!user) {
      setArePollsLoading(false);
    }
    return () => {
      if (unsubscribeToServerTimeOffset) {
        unsubscribeToServerTimeOffset();
      }
      if (unsubscribeFromPolls) {
        unsubscribeFromPolls();
      }
      showNoOpenPollsMessage();
      setCurrentlyOpenPoll(null);
      setIsAnalyticsModalOpen(false);
      setServerTimeOffset(null);
    };
  }, [firebase, user, eid]);

  return (
    <>
      <AnimatePresence>
        {!user && (
          <motion.p
            style={{
              color: '#bdbdbd',
              fontWeight: '300',
              margin: '2px 3.8px',
              fontSize: '1rem',
              textAlign: 'left'
            }}
            initial="initial"
            animate="animate"
            exit="exit"
            variants={fadeInAndOutVariants()}>
            Polls only available for registered users
          </motion.p>
        )}
        {user && !arePollsLoading && !isAnswerPollModalOpen && (
          <motion.p
            style={{ fontStyle: 'italic', fontWeight: '300' }}
            initial="initial"
            animate="animate"
            exit="exit"
            variants={fadeInAndOutVariants()}>
            No Open Polls At The Moment
          </motion.p>
        )}
      </AnimatePresence>
      {arePollsLoading && (
        <LoadingSpinnerWrapper>
          <LoadingSpinner style={{ width: '6rem', color: selectedEvent.colors.tertiary }} />
        </LoadingSpinnerWrapper>
      )}
      <AnimatePresence>
        {isAnswerPollModalOpen && currentlyOpenPoll?.type === 'general poll' && (
          <AnswerPollModal
            poll={currentlyOpenPoll}
            isAnswerPollModalOpen={isAnswerPollModalOpen}
            setIsAnswerPollModalOpen={setIsAnswerPollModalOpen}
            serverTimeOffset={serverTimeOffset}
          />
        )}
        {isAnswerPollModalOpen && currentlyOpenPoll?.type === 'word cloud' && (
          <WordPollModal
            poll={currentlyOpenPoll}
            isAnswerPollModalOpen={isAnswerPollModalOpen}
            setIsAnswerPollModalOpen={setIsAnswerPollModalOpen}
            serverTimeOffset={serverTimeOffset}
          />
        )}
        {isAnswerPollModalOpen && currentlyOpenPoll?.type === 'feedback poll' && (
          <FeedbackAnswerModal
            poll={currentlyOpenPoll}
            isAnswerPollModalOpen={isAnswerPollModalOpen}
            setIsAnswerPollModalOpen={setIsAnswerPollModalOpen}
            serverTimeOffset={serverTimeOffset}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isAnalyticsModalOpen &&
          pollAnalyticsBeingCurrentlySharedWithUsers?.type === 'general poll' && (
            <GeneralAnalyticsModal
              poll={pollAnalyticsBeingCurrentlySharedWithUsers}
              setIsAnalyticsModalOpen={setIsAnalyticsModalOpen}
            />
          )}
      </AnimatePresence>
      <AnimatePresence>
        {isAnalyticsModalOpen &&
          pollAnalyticsBeingCurrentlySharedWithUsers?.type === 'feedback poll' && (
            <FeedbackAnalyticsModal
              poll={pollAnalyticsBeingCurrentlySharedWithUsers}
              setIsAnalyticsModalOpen={setIsAnalyticsModalOpen}
            />
          )}
      </AnimatePresence>
      <AnimatePresence>
        {isAnalyticsModalOpen &&
          pollAnalyticsBeingCurrentlySharedWithUsers?.type === 'word cloud' && (
            <WordCloudAnalyticsModal
              poll={pollAnalyticsBeingCurrentlySharedWithUsers}
              setIsAnalyticsModalOpen={setIsAnalyticsModalOpen}
            />
          )}
      </AnimatePresence>
    </>
  );
};

const LoadingSpinnerWrapper = styled.div`
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  > svg {
    margin-bottom: 0.9rem;

    @media screen and (min-width: 410px) {
      margin-bottom: 1.125rem;
    }

    @media screen and (min-width: 1150px) and (max-width: 1365px) {
      margin-bottom: 0.9rem;
    }

    @media screen and (min-width: 1366px) {
      margin-bottom: 1.125rem;
    }
  }
`;

export default UserPanel;
