import React, { useState, useEffect, useContext } from 'react';
import { FirebaseContext } from 'context';
import styled, { css } from 'styled-components';
import { Chat, Polls, ParticipantModal, Participants, QAndA } from 'components';
import { AnimatePresence } from 'framer-motion';
import { breakpoints } from 'styles';

const Interaction = ({
  colors,
  eid,
  eventStatus,
  forcedActiveTab,
  questionCurrentlyBeingAnsweredLive,
  isChatEnabled,
  isQAndAEnabled,
  isPollsEnabled,
  isParticipantsEnabled,
  activeTab,
  setActiveTab
}) => {
  const { firebase, user } = useContext(FirebaseContext);
  const [participantModalData, setParticipantModalData] = useState(false);
  const [chatTextInputValue, setChatTextInputValue] = useState('');
  const [chatPreviousTextInputValue, setChatPreviousTextInputValue] = useState('');
  const [editingComment, setEditingComment] = useState(false);
  const [idOfCommentCurrentlyBeingEdited, setIdOfCommentCurrentlyBeingEdited] = useState(null);
  const [textOfCommentBeforeBeingEdited, setTextOfCommentBeforeBeingEdited] = useState('');

  useEffect(() => {
    if (
      user &&
      (!user.isModerator || !user.isModerator?.includes(eid)) &&
      forcedActiveTab &&
      activeTab !== forcedActiveTab
    ) {
      setActiveTab(forcedActiveTab);
    }
    return () => {
      if (user?.isModerator?.includes(eid) && forcedActiveTab) {
        firebase.interaction.forceActiveTab({ eid, tabName: null });
      }
    };
  }, [user, forcedActiveTab]);

  useEffect(() => {
    if (participantModalData) {
      setParticipantModalData(false);
    }
  }, [activeTab, forcedActiveTab, eid]);

  const handleParticipantAvatarOrNameClick = (participant) => {
    if (participant) {
      setParticipantModalData({
        avatarUrl: participant.avatarUrl,
        name: participant.name,
        company: participant.company,
        socials: participant.socials,
        uid: participant.uid
      });
    } else {
      setParticipantModalData(false);
    }
  };

  return (
    <Wrapper>
      <Tabs>
        {isChatEnabled && (
          <Tab isActive={activeTab === 'Chat'} onClick={() => setActiveTab('Chat')} colors={colors}>
            Chat
          </Tab>
        )}
        {isQAndAEnabled && (
          <Tab isActive={activeTab === 'Q&A'} onClick={() => setActiveTab('Q&A')} colors={colors}>
            Q & A
          </Tab>
        )}
        {isPollsEnabled && (
          <Tab
            isActive={activeTab === 'Polls'}
            onClick={() => setActiveTab('Polls')}
            colors={colors}>
            Polls
          </Tab>
        )}
        {isParticipantsEnabled && (
          <Tab
            isActive={activeTab === 'Participants'}
            onClick={() => setActiveTab('Participants')}
            colors={colors}>
            Participants
          </Tab>
        )}
      </Tabs>
      <Modules>
        {isChatEnabled && (
          <AnimatePresence>
            {activeTab === 'Chat' && (
              <Chat
                colors={colors}
                eid={eid}
                eventStatus={eventStatus}
                handleParticipantAvatarOrNameClick={handleParticipantAvatarOrNameClick}
                chatTextInputValue={chatTextInputValue}
                setChatTextInputValue={setChatTextInputValue}
                chatPreviousTextInputValue={chatPreviousTextInputValue}
                setChatPreviousTextInputValue={setChatPreviousTextInputValue}
                editingComment={editingComment}
                setEditingComment={setEditingComment}
                idOfCommentCurrentlyBeingEdited={idOfCommentCurrentlyBeingEdited}
                setIdOfCommentCurrentlyBeingEdited={setIdOfCommentCurrentlyBeingEdited}
                textOfCommentBeforeBeingEdited={textOfCommentBeforeBeingEdited}
                setTextOfCommentBeforeBeingEdited={setTextOfCommentBeforeBeingEdited}
                isParticipantsEnabled={isParticipantsEnabled}
              />
            )}
          </AnimatePresence>
        )}
        {isQAndAEnabled && (
          <AnimatePresence>
            {activeTab === 'Q&A' && (
              <QAndA
                colors={colors}
                eid={eid}
                questionCurrentlyBeingAnsweredLive={questionCurrentlyBeingAnsweredLive}
              />
            )}
          </AnimatePresence>
        )}
        {isPollsEnabled && (
          <AnimatePresence>
            {activeTab === 'Polls' && <Polls eid={eid} colors={colors} />}
          </AnimatePresence>
        )}
        {isParticipantsEnabled && (
          <>
            <AnimatePresence>
              {activeTab === 'Participants' && (
                <Participants
                  colors={colors}
                  eid={eid}
                  handleParticipantAvatarOrNameClick={handleParticipantAvatarOrNameClick}
                />
              )}
            </AnimatePresence>
            <AnimatePresence>
              {participantModalData && (
                <ParticipantModal
                  colors={colors}
                  handleParticipantAvatarOrNameClick={handleParticipantAvatarOrNameClick}
                  participant={participantModalData}
                  eid={eid}
                />
              )}
            </AnimatePresence>
          </>
        )}
      </Modules>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  height: 500px;
  justify-self: center;
  max-width: 500px;
  position: relative;
  width: 100%;

  ${breakpoints.lg} {
    height: 100%;
    justify-self: unset;
    max-width: unset;
  }
`;

const Tabs = styled.div`
  align-items: flex-end;
  background-color: transparent;
  display: flex;
  height: 2.875rem;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const Tab = styled.div`
  align-items: center;
  border: 0.071em solid #bdbdbd;
  cursor: pointer;
  display: flex;
  font-size: 0.875rem;
  font-weight: 500;
  justify-content: center;
  padding: 0 1.125em;
  text-transform: uppercase;
  transition: all 200ms ease-in-out;

  ${({ isActive }) =>
    isActive
      ? css`
          background-color: #fff;
          border-top-color: ${({ colors }) => colors.tertiary};
          border-top-width: 0.357em;
          color: #3c3c3c;
          height: 3.286em;
          width: 100%;
        `
      : css`
          background-color: #e5e5e5;
          border-top-color: #bdbdbd;
          border-top-width: 0.071em;
          color: #bdbdbd;
          height: 2.929em;
          width: 100%;

          &:first-child {
            border-right: none;
          }

          &:last-child {
            border-left: none;
          }
        `}
`;

const Modules = styled.div`
  background-color: #fff;
  border: 1px solid #bdbdbd;
  border-top: none;
  height: 100%;
  margin-top: 2.875rem;
  position: relative;
  width: 100%;

  > div {
    height: 100%;
    position: absolute;
    width: 100%;
  }
`;

export default Interaction;
