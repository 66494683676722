import * as React from 'react';

function SvgBgMoon1(props) {
  return (
    <svg viewBox="0 0 202 236" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M201.566 174.201C183.226 176.209 133.105 178.259 99.9853 150.308C59.0912 115.579 59.7462 49.9237 59.8031 47.1471V46.1362C59.7177 30.8863 61.2982 15.6649 64.5162 0.742554C54.8053 7.26397 46.0199 15.0669 38.4021 23.952C10.2233 56.8296 0.597798 101.711 12.8005 143.245C14.5377 142.462 16.3175 141.822 18.1401 141.295C38.872 135.443 60.4439 147.375 66.4954 168.035C69.3574 177.974 68.0902 188.639 62.9927 197.624C61.0562 201.112 58.5928 204.288 55.7023 207.05C95.2865 239.031 149.964 244.783 195.357 221.759C199.729 206.295 201.822 190.277 201.566 174.201Z"
        fill="#78BE20"
      />
      <path
        d="M56.4997 170.855C52.285 155.534 36.4371 146.549 21.1303 150.764C5.80921 154.978 -3.17554 170.826 1.03918 186.133C5.25389 201.454 21.1018 210.453 36.4086 206.224C36.4798 206.196 36.5652 206.181 36.6364 206.167C51.8009 201.81 60.6575 186.076 56.4997 170.855Z"
        fill="#78BE20"
      />
    </svg>
  );
}

export default SvgBgMoon1;
