import React, { useContext, useState, useEffect } from 'react';
import { Button, ErrorMessage, LoadingSpinner, Modal, LoginModal } from 'components';
import styled from 'styled-components';
import { LocalContext, FirebaseContext } from 'context';
import Autocomplete from 'react-google-autocomplete';
import BaseSelect from 'react-select';
import { motion, AnimatePresence } from 'framer-motion';
import { hexToRGB, capitaliseFirstLetterOfText, checkIfNameIncludesOnlyStandardChars } from 'utils';
import { fadeInAndOutVariants, fadeInAndOutAndAnimateHeightVariants } from 'styles';
import {
  FormContainer,
  FormInput,
  FormChecks,
  FormCheckbox,
  FormInputLabel,
  FormTerms
} from '../FormComponents';
import FixRequiredSelect from '../FixRequiredSelect';

function FormSelect(props) {
  return <FixRequiredSelect {...props} SelectComponent={BaseSelect} options={props.options} />;
}

const ageRangeOptions = [
  {
    value: 'Under 20',
    label: 'Under 20'
  },
  {
    value: '20 - 24',
    label: '20 - 24'
  },
  {
    value: '25 - 29',
    label: '25 - 29'
  },
  {
    value: '30 - 34',
    label: '30 - 34'
  },
  {
    value: '35 - 39',
    label: '35 - 39'
  },
  {
    value: '40 - 49',
    label: '40 - 49'
  },
  {
    value: '50 - 59',
    label: '50 - 59'
  },
  {
    value: 'Over 60',
    label: 'Over 60'
  }
];

const numberOfEmployeesOptions = [
  {
    value: '0 - 9',
    label: '0 - 9'
  },
  {
    value: '10 - 49',
    label: '10 - 49'
  },
  {
    value: '50 - 250',
    label: '50 - 250'
  },
  {
    value: '250+',
    label: '250+'
  }
];

const sectorOptions = [
  {
    value: 'Agriculture',
    label: 'Agriculture'
  },
  {
    value: 'Business',
    label: 'Business'
  },
  {
    value: 'Construction',
    label: 'Construction'
  },
  {
    value: 'Engineering',
    label: 'Engineering'
  },
  {
    value: 'Environmental Goods & Services',
    label: 'Environmental Goods & Services'
  },
  {
    value: 'Food & Drink',
    label: 'Food & Drink'
  },
  {
    value: 'Green Technology',
    label: 'Green Technology'
  },
  {
    value: 'Health',
    label: 'Health'
  },
  {
    value: 'ICT Manufacturing & Services',
    label: 'ICT Manufacturing & Services'
  },
  {
    value: 'Financial Services',
    label: 'Financial Services'
  },
  {
    value: 'Life Sciences',
    label: 'Life Sciences'
  },
  {
    value: 'Manufacturing',
    label: 'Manufacturing'
  },
  {
    value: 'Media/Audiovisual',
    label: 'Media/Audiovisual'
  },
  {
    value: 'Mixed',
    label: 'Mixed'
  },
  {
    value: 'Pharmaceutical',
    label: 'Pharmaceutical'
  },
  {
    value: 'Retail',
    label: 'Retail'
  },
  {
    value: 'Services',
    label: 'Services'
  },
  {
    value: 'Technology',
    label: 'Technology'
  },
  {
    value: 'Tourism & Travel',
    label: 'Tourism & Travel'
  },
  {
    value: 'Transportation',
    label: 'Transportation'
  },
  {
    value: 'Visual Arts',
    label: 'Visual Arts'
  },
  {
    value: 'Wholesale Trade',
    label: 'Wholesale Trade'
  },
  {
    value: 'Aviation',
    label: 'Aviation'
  },
  {
    value: 'Homemaker',
    label: 'Homemaker'
  },
  {
    value: 'Legal',
    label: 'Legal'
  },
  {
    value: 'Other',
    label: 'Other'
  }
];

const resetForm = () => ({
  firstName: '',
  surname: '',
  ageRange: '',
  email: '',
  confirmEmail: '',
  phoneNumber: '',
  numberOfEmployees: '',
  sector: '',
  company: '',
  companyAddress: '',
  termsAndConditions: false,
  referringUrl:
    typeof window !== 'undefined' &&
    JSON.parse(window.localStorage.getItem('referringUrlInnovationForum'))?.data
});

const customDropdownMenuStyles = () => ({
  container: (provided, state) => ({
    ...provided,
    alignItems: 'center',
    background: 'transparent',
    border: state.isFocused ? '0.188em solid #01788C' : '0.063em solid #01788C',
    display: 'flex',
    fontFamily: "'droid-sans', sans-serif",
    fontSize: '1rem',
    fontWeight: 'bold',
    height: '3rem',
    letterSpacing: '0.031rem',
    padding: '0'
  }),
  control: (provided) => ({
    ...provided,
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
    width: '100%',
    padding: '0 0 0 1rem',
    cursor: 'pointer',
    height: '100%'
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#01788C',
    '&:hover': {
      color: '#01788C'
    }
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  input: (provided) => ({
    ...provided,
    color: '#01788C'
  }),
  menu: (provided) => ({
    ...provided,
    background: '#01788C',
    border: '0.214em solid #01788C',
    borderRadius: '0',
    fontFamily: "'droid-sans', sans-serif",
    fontSize: '0.875rem',
    fontWeight: 'bold',
    left: '-0.214em',
    letterSpacing: '0.071em',
    margin: '0',
    width: 'calc(100% + 0.429em)'
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    color: '#01788C'
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? hexToRGB({ color: '#78BE20', alpha: 1 }) : 'transparent',
    paddingLeft: '1.25rem',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#002a3a'
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: "'droid-sans', sans-serif",
    fontStyle: 'italic',
    fontWeight: 'bold',
    fontSize: '1rem',
    color: '#01788C'
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#01788C'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0'
  })
});

const RegistrationForm = ({ event, colors }) => {
  const { theme } = useContext(LocalContext);
  const { firebase } = useContext(FirebaseContext);
  const { setANewUserHasRegisteredForAnEvent } = useContext(LocalContext);
  const [formValues, setFormValues] = useState(resetForm());
  const [errorMessage, setErrorMessage] = useState('');
  const [userAlreadyExists, setUserAlreadyExists] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState({
    submitting: false,
    submitted: false
  });
  const [disableSubmitButtonWhileCheckingEmailAddress, setDisableSubmitButtonWhileCheckingInputs] =
    useState(false);
  let isMounted = true;
  let debounce;
  const debounceTimeout = 750;

  useEffect(
    () => () => {
      isMounted = false;
    },
    []
  );

  useEffect(() => {
    const { firstName } = formValues;
    if (firstName) {
      setDisableSubmitButtonWhileCheckingInputs(true);
      debounce = setTimeout(() => {
        if (!checkIfNameIncludesOnlyStandardChars(firstName)) {
          setErrorMessage('Please include only standard characters in your name');
        }
        setDisableSubmitButtonWhileCheckingInputs(false);
      }, debounceTimeout);
    }
    return () => clearTimeout(debounce);
  }, [formValues.firstName]);

  useEffect(() => {
    const { surname } = formValues;
    if (surname) {
      setDisableSubmitButtonWhileCheckingInputs(true);
      debounce = setTimeout(() => {
        if (!checkIfNameIncludesOnlyStandardChars(surname)) {
          setErrorMessage('Please include only standard characters in your name');
        }
        setDisableSubmitButtonWhileCheckingInputs(false);
      }, debounceTimeout);
    }
    return () => clearTimeout(debounce);
  }, [formValues.surname]);

  useEffect(() => {
    const { email } = formValues;
    if (email) {
      setDisableSubmitButtonWhileCheckingInputs(true);
      debounce = setTimeout(async () => {
        try {
          const { data: _userAlreadyExists } =
            await firebase.registration.checkIfAccountAlreadyExists({ email });
          if (_userAlreadyExists) {
            setUserAlreadyExists(true);
            throw new Error(
              "This email address already belongs to an account. Click 'Login' above if it belongs to you."
            );
          } else if (userAlreadyExists) {
            setUserAlreadyExists(false);
            setErrorMessage('');
          }
        } catch (error) {
          setErrorMessage(error.message);
        } finally {
          setDisableSubmitButtonWhileCheckingInputs(false);
        }
      }, debounceTimeout);
    }
    return () => clearTimeout(debounce);
  }, [formValues.email]);

  const handleInputChange = (e) => {
    e.persist();
    const { name, type, checked, value } = e.target;
    if (errorMessage && !userAlreadyExists && name !== 'email') {
      setErrorMessage('');
    }
    setFormValues((currentStates) => ({
      ...currentStates,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleWorkplaceAutocompleteSelection = (res) => {
    const { name } = res;
    const company = name.split(',')[0];
    const companyAddress = name.split(', ')?.slice(1)?.join(', ');
    if (errorMessage && !userAlreadyExists) {
      setErrorMessage('');
    }
    setFormValues((currentStates) => ({
      ...currentStates,
      company,
      companyAddress
    }));
  };

  async function handleSubmit(e) {
    e.preventDefault();

    if (errorMessage || submissionStatus.submitting) {
      return;
    }

    const {
      firstName,
      surname,
      ageRange,
      email,
      confirmEmail,
      company,
      companyAddress,
      phoneNumber,
      numberOfEmployees,
      sector,
      termsAndConditions
    } = formValues;

    if (!firstName) {
      setErrorMessage('Please include your first name');
      return;
    }

    if (!surname) {
      setErrorMessage('Please include a surname');
      return;
    }

    if (!ageRange) {
      setErrorMessage('Please select an age range');
      return;
    }

    if (!company) {
      setErrorMessage('Please include your company name');
      return;
    }

    if (!companyAddress) {
      setErrorMessage('Please include your company address');
      return;
    }

    if (!phoneNumber) {
      setErrorMessage('Please include your phone number');
      return;
    }

    if (!numberOfEmployees) {
      setErrorMessage('Please select your average number of employees');
      return;
    }

    if (!sector) {
      setErrorMessage('Please select your sector');
      return;
    }

    if (!email) {
      setErrorMessage('Please include an email address');
      return;
    }

    if (email !== confirmEmail) {
      setErrorMessage('Email addresses do not match. Please check.');
      return;
    }

    if (userAlreadyExists) {
      setErrorMessage(
        "This email address already belongs to an account. Click 'Login' above if it belongs to you."
      );
      return;
    }

    if (!termsAndConditions) {
      setErrorMessage('You must agree to our terms & conditions to register');
      return;
    }

    try {
      setSubmissionStatus({
        submitting: true,
        submitted: false
      });

      const actionCodeSettings = {
        url: window.location.href,
        handleCodeInApp: true
      };

      const { data } = await firebase.emails.sendSignInWithMagicLinkEmail({
        firstName: capitaliseFirstLetterOfText(firstName),
        email,
        eventName: event?.name || 'The Skillnet Innovation Exchange LIVE',
        actionCodeSettings
      });

      if (data.Message === 'OK') {
        setSubmissionStatus({
          submitting: false,
          submitted: true
        });
        window.localStorage.setItem(
          'newUserInnovationForum',
          JSON.stringify({
            ...formValues,
            eventName: event?.name || '',
            eid: event?.eid || '2',
            eventSlug: event?.slug || 'skillnet-innovation-exchange-live',
            actionCodeSettings
          })
        );
        setANewUserHasRegisteredForAnEvent(true);
      } else {
        setSubmissionStatus({
          submitting: false,
          submitted: false
        });
        setErrorMessage('Error sending email. Please try again');
      }

      setFormValues(resetForm());
    } catch (error) {
      console.error(error);

      if (isMounted) {
        setErrorMessage(error.message);
      }

      setSubmissionStatus({
        submitting: false,
        submitted: false
      });
    }
  }

  return (
    <StyledFormContainer onSubmit={(e) => handleSubmit(e)} $theme={theme}>
      <Title theme={theme} reduce={event?.status === 'past'}>
        <h4>
          {event?.status === 'past' ? 'Register to watch the recorded broadcast' : 'Register Here'}
        </h4>
        <span>
          Already registered?&nbsp;
          <Modal
            trigger={
              <span
                style={{
                  color: `${theme.secondary}`,
                  fontSize: '0.875rem',
                  textAlign: 'center',
                  marginBottom: '1.5em',
                  textDecoration: 'underline'
                }}>
                Click here!
              </span>
            }
            disableCloseOnClickOutside
            modalContent={<LoginModal colors={colors} />}
          />
        </span>
      </Title>
      <div
        style={{
          position: 'relative',
          display: 'grid',
          gridTemplateRows: '1fr',
          gridTemplateColumns: '1fr'
        }}>
        <motion.div
          style={{
            gridRow: 1,
            gridColumn: 1
          }}
          variants={fadeInAndOutVariants()}
          initial="initial"
          animate="animate"
          exit="exit">
          <Fields>
            <FormInput
              theme={theme}
              id="firstName"
              name="firstName"
              onChange={handleInputChange}
              placeholder="First Name*"
              type="text"
              value={formValues.firstName}
              required
            />
            <FormInput
              theme={theme}
              id="surname"
              name="surname"
              onChange={handleInputChange}
              placeholder="Surname*"
              type="text"
              value={formValues.surname}
              required
            />
            <FormSelect
              id="ageRange"
              name="ageRange"
              required
              defaultValue={formValues.ageRange}
              value={ageRangeOptions.filter(({ value }) => value === formValues.ageRange)}
              controlShouldRenderValue
              onChange={({ value }) => {
                setFormValues((currentStates) => ({
                  ...currentStates,
                  ageRange: value
                }));
              }}
              placeholder="Age Range of Participant*"
              styles={customDropdownMenuStyles({ colors, theme })}
              options={ageRangeOptions}
            />
            <FormWorkplaceAutoComplete
              id="company"
              name="company"
              theme={theme}
              apiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY}
              options={{
                types: ['establishment'],
                componentRestrictions: { country: 'ie' },
                fields: ['name']
              }}
              onChange={handleInputChange}
              onPlaceSelected={handleWorkplaceAutocompleteSelection}
              placeholder="Company Name*"
              type="select"
              required
              value={formValues.company}
            />
            <FormInput
              theme={theme}
              id="companyAddress"
              name="companyAddress"
              onChange={handleInputChange}
              placeholder="Company Address*"
              type="text"
              value={formValues.companyAddress}
              required
            />
            <FormInput
              theme={theme}
              id="phoneNumber"
              name="phoneNumber"
              onChange={handleInputChange}
              placeholder="Phone Number*"
              type="tel"
              value={formValues.phoneNumber}
              required
            />
            <FormSelect
              id="numberOfEmployees"
              name="numberOfEmployees"
              required
              defaultValue={formValues.numberOfEmployees}
              value={numberOfEmployeesOptions.filter(
                ({ value }) => value === formValues.numberOfEmployees
              )}
              controlShouldRenderValue
              onChange={({ value }) => {
                setFormValues((currentStates) => ({
                  ...currentStates,
                  numberOfEmployees: value
                }));
              }}
              placeholder="Average Number Of Employees*"
              styles={customDropdownMenuStyles({ colors, theme })}
              options={numberOfEmployeesOptions}
            />
            <FormSelect
              id="sector"
              name="sector"
              required
              defaultValue={formValues.sector}
              value={sectorOptions.filter(({ value }) => value === formValues.sector)}
              controlShouldRenderValue
              onChange={({ value }) => {
                setFormValues((currentStates) => ({
                  ...currentStates,
                  sector: value
                }));
              }}
              placeholder="Sector*"
              styles={customDropdownMenuStyles({ colors, theme })}
              options={sectorOptions}
            />
            <FormInput
              theme={theme}
              id="email"
              name="email"
              onChange={handleInputChange}
              placeholder="Email Address*"
              type="email"
              value={formValues.email}
              required
            />
            <FormInput
              theme={theme}
              id="confirmEmail"
              name="confirmEmail"
              onChange={handleInputChange}
              placeholder="Confirm Email Address*"
              type="email"
              value={formValues.confirmEmail}
              required
            />
          </Fields>
          <FormChecks>
            <FormInputLabel hide htmlFor="termsAndConditions">
              Terms And Conditions
            </FormInputLabel>
            <FormCheckbox
              $theme={theme}
              checked={formValues.termsAndConditions}
              id="termsAndConditions"
              name="termsAndConditions"
              onChange={handleInputChange}
              required
              type="checkbox"
            />
            <FormTerms theme={theme}>
              {event?.termsAndConditions || (
                <p>
                  This event is reserved exclusively for Skillnet Innovation Exchange.
                  <br />
                  By ticking this box, I confirm my registration for this event
                </p>
              )}
            </FormTerms>
          </FormChecks>
          <ErrorMessage
            style={{ paddingTop: '1.5rem' }}
            errorMessage={errorMessage}
            variants={fadeInAndOutAndAnimateHeightVariants()}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '1.25rem'
            }}>
            {submissionStatus.submitted ? (
              <div
                style={{
                  textAlign: 'center',
                  marginBottom: '0.5rem',
                  color: theme.tertiary || 'theme.secondary',
                  width: '90%'
                }}>
                Check your inbox to verify your email address and complete your registration.
                Can&apos;t find our email? Be sure to check your junk!
              </div>
            ) : (
              <Button
                type="submit"
                width="7.5rem"
                whileTap={{ scale: 0.95 }}
                disabled={userAlreadyExists || disableSubmitButtonWhileCheckingEmailAddress}
                style={{
                  alignItems: 'center',
                  alignSelf: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  position: 'relative',
                  opacity: 1
                }}>
                {
                  <AnimatePresence>
                    {submissionStatus.submitting && (
                      <LoadingSpinner
                        style={{ width: '2rem', color: 'white', position: 'absolute' }}
                      />
                    )}
                  </AnimatePresence>
                }
                {
                  <AnimatePresence>
                    {!submissionStatus.submitting && (
                      <motion.span
                        variants={fadeInAndOutVariants()}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        style={{ color: 'white' }}>
                        Submit
                      </motion.span>
                    )}
                  </AnimatePresence>
                }
              </Button>
            )}
          </div>
        </motion.div>
      </div>
    </StyledFormContainer>
  );
};

const StyledFormContainer = styled(FormContainer)`
  background: rgba(237, 237, 237, 0.9);
  input {
    margin-bottom: 0;
  }
  @media screen and (min-width: 100rem) {
    padding: 4rem 1.25rem 2.5rem 1.25rem;
    padding-top: 4rem;
  }
`;

const Title = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  gap: 0.5rem;
  justify-content: center;
  margin-bottom: 1.5rem;
  h4,
  span {
    color: ${({ theme }) => theme.secondary};
  }
  h4 {
    font-family: 'droid-sans', sans-serif;
    font-size: ${({ reduce }) => (reduce ? '22px' : '24px')};
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
  }
  span {
    display: flex;
    font-family: 'droid-sans', sans-serif;
    text-align: center;
  }
`;

const Fields = styled.div`
  display: grid;
  grid-row-gap: 0.563rem;
  grid-template-columns: 1fr;
  margin-bottom: 0.563rem;
`;

const FormWorkplaceAutoComplete = styled(Autocomplete)`
  align-items: center;
  background: transparent;
  border: 0.063rem solid ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.secondary};
  display: flex;
  font-family: 'droid-sans', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  height: 3rem;
  letter-spacing: 0.031rem;
  outline: none;
  padding: 0 1rem;
  ::placeholder {
    color: ${({ theme }) => theme.secondary};
    font-family: 'droid-sans', sans-serif;
    font-size: 1rem;
    font-style: italic;
    font-weight: 700;
  }
`;

export default RegistrationForm;
