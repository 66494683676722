import React, { useContext, useState } from 'react';
import { navigate } from 'gatsby';
import styled, { css } from 'styled-components';
import { Button, CustomError, ErrorMessage, LoadingSpinner } from 'components';
import { LocalContext, FirebaseContext } from 'context';
import { clearQueryParams } from 'utils';
import { fadeInAndOutAndAnimateHeightVariants } from 'styles';
import { FormContainer, FormLabel, FormInput } from '../FormComponents';

const LoginModal = ({
  setShowLoginModal,
  userRegisteredUsingAnotherDeviceOrBrowser,
  colors,
  eventSlug
}) => {
  const { theme, selectedEvent } = useContext(LocalContext);
  const { firebase } = useContext(FirebaseContext);
  const [formValues, setFormValues] = useState({
    email: ''
  });
  const [magicLinkEmailStatus, setMagicLinkEmailStatus] = useState({
    sending: false,
    sent: false
  });
  const [error, setError] = useState({
    code: '',
    message: userRegisteredUsingAnotherDeviceOrBrowser
      ? 'It seems your last website interaction was from another device or browser. Please enter your email address to be sent a new link, which you can click to continue with this browser.'
      : ''
  });

  const handleInputChange = (e) => {
    if (!userRegisteredUsingAnotherDeviceOrBrowser) {
      setError({
        code: '',
        message: ''
      });
    }

    const { name, value } = e.target;
    setFormValues((currentStates) => ({
      ...currentStates,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (userRegisteredUsingAnotherDeviceOrBrowser && error.code === 'auth/user-not-found') {
      clearQueryParams();
      if (eventSlug) {
        navigate(`/events/${eventSlug}`);
      } else {
        setShowLoginModal(false);
      }
      setShowLoginModal(false);
      return;
    }

    if (!userRegisteredUsingAnotherDeviceOrBrowser) {
      setError({
        code: '',
        message: ''
      });
    }

    const { email } = formValues;

    try {
      setMagicLinkEmailStatus({
        sending: true,
        sent: false
      });

      const { data: userExists } = await firebase.registration.checkIfAccountAlreadyExists({
        email
      });

      if (!userExists) {
        setMagicLinkEmailStatus({
          sending: false,
          sent: false
        });
        throw new CustomError('auth/user-not-found');
      }

      setMagicLinkEmailStatus({
        sending: true,
        sent: false
      });

      const actionCodeSettings = {
        url: window.location.href,
        handleCodeInApp: true
      };

      const { data } = await firebase.emails.sendSignInWithMagicLinkEmail({
        firstName: '',
        email,
        eventName: 'The Skillnet Innovation Exchange LIVE',
        actionCodeSettings
      });

      if (data?.Message === 'OK') {
        setMagicLinkEmailStatus({
          sending: false,
          sent: true
        });
        window.localStorage.setItem(
          'existingUserInnovationForum',
          JSON.stringify({
            ...formValues,
            eid: selectedEvent?.eid || '2',
            eventSlug: selectedEvent?.slug || ''
          })
        );
        if (userRegisteredUsingAnotherDeviceOrBrowser) {
          clearQueryParams();
        }
        if (error.message) {
          setError({
            code: '',
            message: ''
          });
        }
      }
    } catch (_error) {
      setMagicLinkEmailStatus({
        sending: false,
        sent: false
      });
      switch (_error.code) {
        case 'permission-denied':
          console.error(_error);
          setError({
            code: _error.code,
            message: 'Error logging in. Please contact support'
          });
          break;
        case 'auth/user-not-found':
          console.error(_error.toString());
          setError({
            code: _error.code,
            message: userRegisteredUsingAnotherDeviceOrBrowser
              ? 'There is no user account associated with this email address. Your default/current browser is likely different than the browser you tried registering with. Click below to register again using this default/current browser.'
              : 'There is no user account associated<br />with this email address.'
          });
          break;
        default:
          console.error(_error);
          setError({
            code: _error.code,
            message: _error.message
          });
          break;
      }
    }
  };

  return (
    <StyledFormContainer
      onSubmit={handleSubmit}
      $theme={theme}
      colors={colors}
      userRegisteredUsingAnotherDeviceOrBrowser={userRegisteredUsingAnotherDeviceOrBrowser}>
      {!magicLinkEmailStatus.sent && (
        <>
          <FormLabel>{userRegisteredUsingAnotherDeviceOrBrowser ? 'Whoops...' : 'Login'}</FormLabel>
          {(error.code !== 'auth/user-not-found' ||
            (error.code === 'auth/user-not-found' &&
              !userRegisteredUsingAnotherDeviceOrBrowser)) && (
            <>
              <FormLabel hide htmlFor="email">
                Email
              </FormLabel>
              <FormInput
                id="email"
                name="email"
                onChange={handleInputChange}
                placeholder="Email"
                type="email"
                value={formValues.email}
                required
                style={{
                  marginBottom: '1.5rem'
                }}
              />
            </>
          )}
        </>
      )}
      <ErrorMessage
        errorMessage={error.message}
        style={{
          color: '#fff',
          marginBottom: '0.575em'
        }}
        variants={fadeInAndOutAndAnimateHeightVariants()}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row'
        }}>
        {magicLinkEmailStatus.sent ? (
          <h3
            style={{
              fontSize: '1.25rem',
              fontWeight: 600,
              letterSpacing: '0.01em',
              maxWidth: '17em',
              textAlign: 'center'
            }}>
            Please check your email account that you&apos;ve registered with and click on the email
            we&apos;ve just sent you to login.
          </h3>
        ) : (
          <Button
            style={{
              marginTop: '1rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            type="submit"
            whileTap={{
              scale: 0.95
            }}
            width={userRegisteredUsingAnotherDeviceOrBrowser ? '240px' : '120px'}>
            {magicLinkEmailStatus.sending ? (
              <LoadingSpinner style={{ color: '#fff', width: '2rem' }} />
            ) : userRegisteredUsingAnotherDeviceOrBrowser &&
              error.code === 'auth/user-not-found' ? (
              'Register'
            ) : userRegisteredUsingAnotherDeviceOrBrowser &&
              error.code !== 'auth/user-not-found' ? (
              'Send New Login Link'
            ) : (
              'Login'
            )}
          </Button>
        )}
      </div>
    </StyledFormContainer>
  );
};

const StyledFormContainer = styled(FormContainer)`
  background: ${({ colors }) => colors.primary};
  color: #ffffff !important;
  button {
    background: ${({ theme }) => theme.tertiary};
  }
  h3 {
    color: #ffffff;
    font-family: 'Uni Neue', sans-serif;
  }
  input {
    border: 1px solid #ffffff;
    color: #ffffff;
    ::placeholder {
      color: #ffffff;
    }
  }
  label {
    font-family: 'Uni Neue', sans-serif !important;
  }
  ${({ userRegisteredUsingAnotherDeviceOrBrowser }) =>
    userRegisteredUsingAnotherDeviceOrBrowser &&
    css`
      @media (min-width: 1440px) {
        padding: 2.5rem 1.25rem;
        max-width: 510px;
        width: 100%;
      }
    `}
`;

export default LoginModal;
