import { collection, onSnapshot, query, where } from 'firebase/firestore';

function ModeratorDashboard(firebase) {
  const subscribeToEventData = async ({ eid, snapshot }) =>
    onSnapshot(
      query(
        collection(firebase.fsdb, 'users'),
        where('eventsUserCanAccess', 'array-contains', eid)
      ),
      snapshot
    );

  return {
    subscribeToEventData
  };
}

export default ModeratorDashboard;
